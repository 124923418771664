import { reduxApi } from "../reduxApi";
import { Contact, ContactWithId } from "@/models/contact";
import { Filter, FilterResult } from "@/api/types";

// Endpunkte für die Kontaktverwaltung
const contactsApi = reduxApi.injectEndpoints({
  endpoints: (builder) => ({
    // GET /Contact/:id - gibt den Kontakt mit der ID zurück.
    getContact: builder.query<Contact, string>({
      query: (id) => `contact/${id}`,
    }),
    // DELETE /Contact/:id - löscht den Kontakt mit der ID.
    deleteContact: builder.mutation<void, string>({
      query: (id) => ({
        url: `contact/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Contact"],
    }),
    // PUT /Contact/:id - Aktualisiert den Kontakt mit der ID.
    updateContact: builder.mutation<
      Contact,
      { id: string; body: Partial<Contact> }
    >({
      query: ({ id, body }) => ({
        url: `contact/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Contact"],
    }),
    // POST /contact - erstellt einen neuen Kontakt.
    createContact: builder.mutation<Contact, Contact>({
      query: (body) => ({
        url: `contact/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Contact"],
    }),
    // POST /Contact/Filter - ermöglicht die Suche nach Kontakten anhand verschiedener Kriterien.
    filterContacts: builder.query<
      FilterResult<ContactWithId>,
      Filter<ContactWithId>
    >({
      query: (body) => ({
        url: `contact/filter`,
        method: "POST",
        body,
      }),
      providesTags: ["Contact"],
    }),
  }),
});

export const {
  useGetContactQuery,
  useDeleteContactMutation,
  useUpdateContactMutation,
  useCreateContactMutation,
  useFilterContactsQuery,
} = contactsApi;
