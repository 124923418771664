import { translation } from "@/i18n/translation";

export function formatError(error: any) {
  const i18n = translation;
  const t = i18n.t;
  console.log(error);

  if (typeof error === "string") {
    return error;
  }

  if ("status" in error && error.status === "FETCH_ERROR") {
    return t("error.network");
  }

  if ("status" in error && "data" in error) {
    let body;
    try {
      body = error.data as {
        code?: string;
        context?: { [key: string]: string };
      };
    } catch (e) {
      console.log(e);
    }

    // Try to find a translation for the error code
    const codeKey = `error.app.${body?.code || ""}`;
    console.log(codeKey);
    if (i18n.exists(codeKey)) {
      return t(codeKey, body?.context);
    }

    // More generic error messages for http status codes
    const statusKey = `error.status.${error.status}`;
    if (i18n.exists(statusKey)) {
      return t(statusKey);
    }

    // fallback to generic message for the http method
    const method = error.request?.method || "GET";
    const methodKey = `error.method.${method.toUpperCase()}`;
    return t(methodKey);
  }

  return error + "";
}
