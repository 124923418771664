import React from "react";
import { BiDocument } from "@/models/document";
import { blobUrl } from "@/api/reduxApi";
import { DownloadIcon } from "lucide-react";
import { cn } from "@/lib/utils";
import { cva } from "class-variance-authority";

const DownloadButton = ({
  biDocument,
  className,
}: {
  biDocument: BiDocument;
  className?: string;
}) => {
  const blobData = biDocument.uploadDocument?.blob;
  if (!blobData) {
    return null;
  }
  const url = blobUrl(blobData);
  return (
    <a
      download={true}
      target={"_blank"}
      href={url}
      className={cn(
        className,
        "group text-muted-foreground shadow hover:bg-accent hover:text-foreground",
        "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50",
        "h-9 w-9",
        "group-hover:animate-bounce",
      )}
    >
      <DownloadIcon size={16} className={cn("group-hover:animate-bounce")} />
      {/*{t("model.document.downloadDocument")}*/}
    </a>
  );
};

export default DownloadButton;
