import { useToast } from "../ui/use-toast";

export function useErrorToast() {
  const { toast } = useToast();

  return {
    errorToast: (error: any) => {
      console.error(error);
      toast({
        title: "Fehler",
        description: "Es ist ein Fehler aufgetreten.",
      });
    },
  };
}
