import React from "react";
import { cn } from "@/lib/utils";

interface HorizontalGradientLineProps {
  className?: string;
}

const HorizontalGradientLine = ({ className }: HorizontalGradientLineProps) => {
  return (
    <hr
      className={cn(
        "h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-muted-foreground to-transparent opacity-25",
        className,
      )}
    />
  );
};

export default HorizontalGradientLine;
