import React from "react";
import { PencilIcon, PencilOff } from "lucide-react";
import { Button } from "@/components/ui/button";

const EditButton = ({
  isEditing,
  onClick,
}: {
  isEditing: boolean;
  onClick: () => void;
}) => (
  <Button variant="ghost" size="icon" onClick={onClick} className={"group"}>
    {isEditing ? (
      <PencilOff className="h-4 w-4 text-muted-foreground group-hover:-rotate-12 group-hover:text-foreground" />
    ) : (
      <PencilIcon className="h-4 w-4 text-muted-foreground group-hover:-rotate-12 group-hover:text-foreground" />
    )}
  </Button>
);

export default EditButton;
