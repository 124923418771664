import React, { useState, useEffect } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import {
  BarChartIcon,
  TimerIcon,
  ActivityLogIcon,
  CardStackIcon,
} from "@radix-ui/react-icons";

interface NumberCardProps {
  title: string;
  totalAmount: number;
  subtext: string;
  type: string;
  change: number;
}

const NumberCard: React.FC<NumberCardProps> = ({
  title,
  totalAmount,
  subtext,
  type,
  change,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [formattedTotalAmount, setFormattedTotalAmount] = useState<
    string | null
  >(null);

  useEffect(() => {
    setIsLoading(true);
    if (totalAmount) {
      let formatted: string;
      if (type === "runway") {
        formatted = `${totalAmount} Monate`;
      } else {
        formatted =
          totalAmount.toLocaleString("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) + " €";
      }
      setFormattedTotalAmount(formatted);
    }
    if (title && totalAmount !== null) {
      setIsLoading(false);
    }
  }, [title, totalAmount, type]);

  const getRunwayText = (change: number) => {
    return change < 0 ? `-${Math.abs(change)} Monate` : `+${change} Monate`;
  };

  const getChangeText = (change: number) => {
    return change < 0 ? `-${Math.abs(change)}` : `+${change}`;
  };

  const getIcon = (title: string) => {
    switch (title) {
      case "Cash":
        return <CardStackIcon className="h-5 w-5 text-muted-foreground" />;
      case "MRR":
        return <BarChartIcon className="h-5 w-5 text-muted-foreground" />;
      case "Burn Rate":
        return <ActivityLogIcon className="h-5 w-5 text-muted-foreground" />;
      case "Runway":
        return <TimerIcon className="h-5 w-5 text-muted-foreground" />;
      default:
        return null;
    }
  };

  return (
    <Card className="mx-auto w-full max-w-md">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        {isLoading ? (
          <Skeleton className="h-4 w-full max-w-[250px]" />
        ) : (
          <div className="flex w-full flex-row items-center justify-between">
            <CardTitle className="text-md mb-0 flex items-center font-semibold">
              {title}
            </CardTitle>
            <div className="h-6 w-6 flex-shrink-0">{getIcon(title)}</div>
          </div>
        )}
      </CardHeader>
      <CardContent className="space-y-6">
        {isLoading ? (
          <>
            <Skeleton className="h-8 w-full max-w-[100px]" />
            <Skeleton className="h-8 w-full max-w-[150px]" />
          </>
        ) : (
          <div className="flex flex-col">
            <div className="h-8 text-2xl font-medium">
              {formattedTotalAmount}
            </div>
            <div className="flex flex-col items-start space-x-0 md:flex-row md:items-center md:space-x-4">
              <div className="mt-0 h-4 flex-grow text-xs">
                {type !== "runway" ? (
                  <>
                    <span className="font-regular">
                      {getChangeText(change)}%
                    </span>
                    <span className="ml-1 text-muted-foreground">
                      {subtext}
                    </span>
                  </>
                ) : (
                  <>
                    <span className="font-regular">
                      {getRunwayText(change)}
                    </span>
                    <span className="ml-1 text-muted-foreground">
                      {subtext}
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default NumberCard;
