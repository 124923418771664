import { useTranslation } from "react-i18next";
import {
  useCreateGoCardlessRequisitionMutation,
  useListGoCardlessBankInstitutionsQuery,
} from "@/api/endpoints/bankApi";
import React, { useEffect, useState } from "react";
import { Input } from "@/components/ui/input";
import { VerticalFlex } from "@/components/layout/Flex";
import Spinner from "@/components/loading/spinner";
import { Button } from "@/components/ui/button";
import { Plus } from "lucide-react";
import { BankInstitution } from "@/models/bankInstitution";

interface AddBankFormProps {
  close: () => void;
}

const AddBankForm = ({ close }: AddBankFormProps) => {
  const { t } = useTranslation();

  const { data, isLoading } = useListGoCardlessBankInstitutionsQuery(
    undefined,
    {
      skip: !close,
    },
  );

  const [requsitionMutation, requisitionResult] =
    useCreateGoCardlessRequisitionMutation();

  const createRequisition = (id: string) => {
    requsitionMutation({
      institutionId: id,
    }).then((res) => {
      if ("data" in res) {
        window.open(res.data.link, "_self");
      }
    });
  };

  const [searchInputValue, setSearchInputValue] = useState("");
  const [filteredBanks, setFilteredBanks] = useState<BankInstitution[] | null>(
    null,
  );

  const searchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(event.target.value);
  };

  useEffect(() => {
    let handle = setTimeout(() => {
      let results = data?.filter((bank) =>
        bank.name.toLowerCase().includes(searchInputValue.toLowerCase()),
      );
      setFilteredBanks(results || []);
    }, 25);
    return () => {
      clearTimeout(handle);
    };
  }, [searchInputValue, data]);

  return (
    <VerticalFlex className={"items-center justify-center"}>
      <Input
        type="text"
        placeholder={t("component.transactions.modal.institutionInput")}
        value={searchInputValue}
        onChange={searchInputChange}
      />
      {(!filteredBanks || isLoading || requisitionResult.isLoading) && (
        <VerticalFlex
          className={
            "h-[325px] max-h-[325px] w-full items-center justify-center"
          }
        >
          <Spinner size={48}></Spinner>
        </VerticalFlex>
      )}
      {filteredBanks && !requisitionResult.isLoading && (
        <VerticalFlex
          className={"h-auto max-h-[325px] w-full snap-y overflow-y-auto pr-2"}
        >
          {filteredBanks.map((bank) => (
            <div
              key={bank.id}
              className={
                "grid snap-start grid-cols-[auto_1fr_auto] items-center justify-center gap-6 text-sm font-semibold"
              }
            >
              <img
                className="h-12 w-12 rounded-full"
                src={bank.logo}
                alt="BankImag"
              />
              <span title={bank.name} className={"truncate"}>
                {bank.name}
              </span>
              <Button
                title={"verbinden"}
                variant={"ghost"}
                onClick={() => createRequisition(bank.id)}
              >
                <Plus />
              </Button>
            </div>
          ))}
        </VerticalFlex>
      )}
    </VerticalFlex>
  );
};

export default AddBankForm;
