import { HashLoader } from "react-spinners";

const Spinner = ({ size = 75 }: { size?: number }) => {
  return (
    <div className=" flex h-full w-full items-center justify-center">
      <div className="m-auto">
        <HashLoader size={size} color="#bdbdbd" />
      </div>
    </div>
  );
};

export default Spinner;
