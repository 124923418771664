import React, { FC } from "react";
import { BiDocumentKind, DocumentParty } from "@/models/document";
import { Card, CardContent } from "@/components/ui/card";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import { cn } from "@/lib/utils";
import { useFilterContactsQuery } from "@/api/endpoints/contactsApi";
import { FilterEq } from "@/api/types";
import { ContactTypeComponent } from "@/feature/contacts/Contacts";

export const ContactCard: FC<{
  contact: DocumentParty;
  kind?: BiDocumentKind;
}> = ({ contact, kind }) => {
  const { data: contactResult } = useFilterContactsQuery(
    FilterEq("customerNumber", contact.customerNumber),
    { skip: !contact.customerNumber },
  );

  return (
    <Card>
      <CardContent className={"flex flex-row gap-4 rounded-md border p-4"}>
        <HorizontalFlex
          gap={4}
          className={cn(
            "flex-1",
            kind === BiDocumentKind.Invoice ? "flex-row-reverse" : "flex-row",
          )}
        >
          <VerticalFlex className={"flex-1"}>
            <p className="text-l max-w-96 truncate font-medium leading-none">
              {contact.name}
            </p>
            {contact.address && (
              <p className="text-xs text-muted-foreground/80">
                {contact.address.replace(",", " ")}
              </p>
            )}
            {contact.iban && (
              <p className="font-mono text-xs text-muted-foreground/80">
                {contact.iban.replace(/\W/g, "").replace(/(.{4})/g, "$1 ")}
              </p>
            )}
          </VerticalFlex>
          {contact.customerNumber && (
            <VerticalFlex align={"center"}>
              <ContactTypeComponent
                type={contactResult?.items?.[0]?.type}
                hasLabel={false}
              />
              <p className="text-l font-medium leading-none text-muted-foreground/80">
                {contact.customerNumber}
              </p>
            </VerticalFlex>
          )}
        </HorizontalFlex>
      </CardContent>
    </Card>
  );
};
