import { User } from "@/models/user";
import { Company } from "@/models/company";

export interface UserCompany {
  id: string;
  createdAt: string;
  updatedAt: string;
  role: UserRole;
  status: UserCompanyStatus;
  user: User;
  company: Company;
}

export enum UserRole {
  OWNER = "owner",
  NORMAL = "normal",
}

export enum UserCompanyStatus {
  ACTIVE = "active",
  INVITED = "invited",
}
