import Logo from "@/components/page/components/Logo";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Link } from "react-router-dom";
import { RegisterForm } from "@/feature/auth/components/RegisterForm";
import { useTranslation } from "react-i18next";

export function Register() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center flex-auto pt-20">
      <Card className="m-auto max-w-xl bg-card flex flex-col gap-8">
        <CardHeader className="text-center">
          <Logo></Logo>
          <CardTitle className="text-xl">
            {t("component.auth.register.slogan")}
          </CardTitle>
          <CardDescription>
            {t("component.auth.register.subSlogan")}
          </CardDescription>
        </CardHeader>
        <CardContent>
          <RegisterForm />
        </CardContent>
        <CardFooter>
          <div className="mt-4 mx-auto flex flex-col text-center text-sm">
            <p>{t("component.auth.register.alreadyRegistered")}</p>
            <Link to="/login" className="underline">
              {t("component.auth.register.actionLogin")}
            </Link>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
}
