import React, { useContext, useEffect, useState } from "react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { UploadContext } from "@/components/upload/UploadContextProvider";
import { UploadInfoCard } from "@/components/upload/UploadInfoCard";
import { useTranslation } from "react-i18next";
import { Bell, BellDot } from "lucide-react";
import { ScrollArea } from "@/components/ui/scroll-area";
import { t } from "i18next";

const UploadStatusBar = () => {
  const { uploads } = useContext(UploadContext);
  const [openStatePopover, setOpenStatePopover] = useState(uploads.length > 0);
  const { t } = useTranslation();
  const [displayLimit, setDisplayLimit] = useState(25);

  useEffect(() => {
    setOpenStatePopover(uploads.length > 0);
  }, [uploads.length]);

  return (
    <Popover open={openStatePopover} defaultOpen={openStatePopover}>
      <PopoverTrigger
        className={"cursor-pointer rounded-lg p-2 hover:bg-foreground/5"}
        onClick={() => {
          if (!openStatePopover) {
            setOpenStatePopover(true);
          }
        }}
        disabled={openStatePopover}
      >
        {uploads.length > 0 ? (
          <BellDot color="#bdbdbd" />
        ) : (
          <Bell color="#3f3f46" />
        )}
      </PopoverTrigger>
      <PopoverContent
        onEscapeKeyDown={() => setOpenStatePopover(false)}
        onPointerDownOutside={() => setOpenStatePopover(false)}
        align={"end"}
        className={"w-80 gap-y-8 divide-y"}
      >
        <h4>{t("component.notification.heading")}</h4>
        <ScrollArea className={"flex max-h-96 flex-col gap-4"}>
          {uploads.slice(0, displayLimit).length > 0 ? (
            <UploadInfoCard uploads={uploads.slice(0, displayLimit)} />
          ) : (
            <span className={"text-xs text-muted-foreground"}>
              {t("component.notification.empty")}
            </span>
          )}
          {uploads.length > 0 && (
            <DisplayLimitSetter setDisplayLimit={setDisplayLimit} />
          )}
        </ScrollArea>
      </PopoverContent>
    </Popover>
  );
};
export default UploadStatusBar;

interface DisplayLimitSetterProps {
  setDisplayLimit: (value: ((prevState: number) => number) | number) => void;
}

const DisplayLimitSetter = ({ setDisplayLimit }: DisplayLimitSetterProps) => {
  return (
    <div className="mt-4 flex justify-end space-x-2 text-xs text-muted-foreground">
      <button
        className={"hover:text-foreground hover:underline"}
        onClick={() => setDisplayLimit(25)}
      >
        25
      </button>
      <button
        className={"hover:text-foreground hover:underline"}
        onClick={() => setDisplayLimit(50)}
      >
        50
      </button>
      <button
        className={"hover:text-foreground hover:underline"}
        onClick={() => setDisplayLimit(Infinity)}
      >
        {t("component.notification.showAll")}
      </button>
    </div>
  );
};
