import { useEffect, useState } from "react";
import { Progress } from "@/components/ui/progress";
import { BiDocument } from "@/models/document";

const FakeProgressBar = ({
  document,
  eta,
  spread,
}: {
  document: BiDocument;
  eta: number;
  spread: number;
}) => {
  const [now, setNow] = useState(new Date().getTime());

  useEffect(() => {
    let t = setInterval(() => {
      setNow(new Date().getTime());
    }, 1000);
    return () => {
      clearInterval(t);
    };
  }, []);

  let created = new Date(document.createdAt).getTime();
  let seededRandom = (created % 1000) / 1000;
  let inProgressMs = now - created;
  let progress =
    inProgressMs / ((eta - spread) * 1000 + seededRandom * 2 * spread * 10000);

  let isInBatchJob = !!document.uploadDocument?.ocrStartedAt;
  const fakeLoadingProgress = 2 * (sigmoid(progress) - 0.5);

  return (
    <Progress
      className={"h-2 flex-1 opacity-25"}
      value={fakeLoadingProgress * 66 + (isInBatchJob ? 1 : 0) * 34}
    />
  );
};

const sigmoid = (x: number): number => 1 / (1 + Math.exp(-x));

export default FakeProgressBar;
