import { Button } from '@/components/ui/button'
//import { ExternalLink } from './ExternalLink'
import { ArrowRight } from 'lucide-react'

const exampleMessages = [
  {
    heading: 'Wie hat sich mein Umsatz entwickelt?',
    message: `Wie hat sich mein Umsatz entwickelt?`
  },
  {
    heading: 'Wie hoch ist meine Steuerlast?',
    message: `Wie hoch ist meine Steuerlast?`
  },
  {
    heading: 'Kannst du mir eine Zusammenfassung meiner Transaktionen geben?',
    message: `Kannst du mir eine Zusammenfassung meiner Transaktionen geben?`
  }
];

export function EmptyScreen({ setInput }) {
  return (
    <div className="mx-auto max-w-2xl px-4">
      <div className="rounded-lg border bg-background p-8">
        <h1 className="mb-2 text-lg font-semibold">
          Willkommen bei Metrics AI!
        </h1>
        <p className="mb-2 leading-normal text-muted-foreground">
          Erhalte schnelle, klare und präzise Antworten auf deine Finanzfragen. 
        </p>
        <p className="leading-normal text-muted-foreground">
          Probiere es aus:
        </p>
        <div className="mt-4 flex flex-col items-start space-y-2">
          {exampleMessages.map((message, index) => (
            <Button
              key={index}
              variant="link"
              className="h-auto p-0 text-base"
              onClick={() => setInput(message.message)}
            >
              <ArrowRight className="mr-2 text-muted-foreground" />
              {message.heading}
            </Button>
          ))}
        </div>
      </div>
    </div>
  )
}
