import { FC } from "react";

export const Line: FC<{
  h?: boolean;
  v?: boolean;
}> = (props) => {
  if (props.h) {
    return (
      <div
        className={`border-t border-gray-300 justify-self-stretch w-1 h-full`}
      />
    );
  } else {
    return <div className={`border border-gray-300  w-[1px] self-stretch`} />;
  }
};
