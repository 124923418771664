import React from "react";
import { Check, Trash, Trash2 } from "lucide-react";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { useConfirmModal } from "@/components/modal/useConfirmModal";
import { useDocumentDeleteMutation } from "@/api/endpoints/documentApi";
import { toast } from "@/components/ui/use-toast";
import { t } from "i18next";
import { BiDocument } from "@/models/document";
import { useDocumentContext } from "@/feature/documents/DocumentContextProvider";

const DeleteButton = ({
  document,
  beforeDelete,
}: {
  document: BiDocument;
  beforeDelete?: () => boolean;
}) => {
  const { confirmDelete } = useConfirmModal();
  const [docDelete] = useDocumentDeleteMutation();

  const { selectDocumentById } = useDocumentContext();
  const deleteAction: React.MouseEventHandler<HTMLButtonElement> | undefined = (
    e,
  ) => {
    e.preventDefault();
    e.stopPropagation();

    if (beforeDelete && !beforeDelete()) {
      return;
    }

    confirmDelete({
      text: t("component.documents.deleteConfirm", {
        invoiceNumber: document.invoiceNumber,
      }),
      onAction: async () => {
        let res = docDelete(document);

        if ("data" in res) {
          selectDocumentById(undefined);

          toast({
            title: t("common.successDelete"),
            icon: Check,
          });
        }
      },
    });
  };

  return (
    <Button
      variant="ghost"
      size="icon"
      onClick={deleteAction}
      className={"group"}
    >
      <>
        <Trash
          className={cn(
            "h-4 w-4 text-muted-foreground group-hover:hidden group-hover:text-red-700",
            "flex group-hover:hidden",
          )}
        />
        <Trash2
          className={cn(
            "h-4 w-4 text-muted-foreground transition-colors duration-300 group-hover:hidden group-hover:text-red-700",
            "hidden group-hover:flex",
          )}
        />
      </>
    </Button>
  );
};

export default DeleteButton;
