import Spinner from "@/components/loading/spinner";
import UserTable from "./components/UserTable";
import { useFindAllUserQuery } from "@/api/endpoints/userApi";

const UsersContents = () => {
  const { data: userData, isLoading } = useFindAllUserQuery();
  if (isLoading) {
    return <Spinner></Spinner>;
  }

  return <UserTable data={userData ?? []}></UserTable>;
};

export default UsersContents;
