import React from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import { z } from "zod";
import { biDocumentSchema } from "@/models/EditDocumentModel";
import { t } from "i18next";
import { useFilterContactsQuery } from "@/api/endpoints/contactsApi";
import { Input } from "@/components/ui/input";
import { ScrollArea } from "@/components/ui/scroll-area";
import { FilterIn } from "@/api/types";
import { ContactTypeComponent } from "@/feature/contacts/Contacts";
import { HorizontalFlex } from "@/components/layout/Flex";
import { BiDocumentKind } from "@/models/document";
import { ContactWithId } from "@/models/contact";
import { addressToString } from "@/util/address";
import { FormField, FormMessage } from "@/components/ui/form";

const FormContactNameComboBox = ({
  form,
}: {
  form: UseFormReturn<z.infer<typeof biDocumentSchema>>;
}) => {
  const [search, setSearch] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [mouseEnter, setMouseEnter] = React.useState(false);

  let documentKind = useWatch({ name: "kind" });
  let issuerCustomerNumber = useWatch({ name: "issuer.customerNumber" });
  let recipientCustomerNumber = useWatch({ name: "recipient.customerNumber" });

  let customerNumber =
    documentKind == BiDocumentKind.Invoice
      ? recipientCustomerNumber
      : issuerCustomerNumber;

  const { data, isLoading } = useFilterContactsQuery({
    sort: "companyName",
    direction: "asc",
    ...FilterIn("companyName", search),
  });

  const formName =
    documentKind === BiDocumentKind.Invoice ? "recipient.name" : "issuer.name";

  const handleInputChange = (e: { target: { value: string } }) => {
    const value = e.target.value;
    setSearch(value);
    form.setValue(formName, value);
    form.setValue("issuer.customerNumber", null);
    form.setValue("recipient.customerNumber", null);
  };

  const onClickItem = (item: ContactWithId) => {
    if (documentKind === BiDocumentKind.Invoice) {
      form.setValue("recipient.name", item.companyName);
      form.setValue(
        "recipient.address",
        item.addresses && item.addresses[0]
          ? addressToString(item.addresses[0])
          : "",
      );
      form.setValue("recipient.iban", item.paymentInfo?.iban || "");
      form.setValue("recipient.taxId", item.paymentInfo?.taxNumber || "");
      form.setValue("recipient.customerNumber", item.customerNumber);
    } else {
      form.setValue("issuer.name", item.companyName);
      form.setValue(
        "issuer.address",
        item.addresses && item.addresses[0]
          ? addressToString(item.addresses[0])
          : "",
      );
      form.setValue("issuer.iban", item.paymentInfo?.iban || "");
      form.setValue("issuer.taxId", item.paymentInfo?.taxNumber || "");
      form.setValue("issuer.customerNumber", item.customerNumber);
    }
    setOpen(false);
    setMouseEnter(false);
  };

  let value = useWatch({ name: formName });
  return (
    <div className={"relative"} key={documentKind + ""}>
      <Input {...form.register("issuer.customerNumber")} type={"hidden"} />
      <Input {...form.register("recipient.customerNumber")} type={"hidden"} />
      <HorizontalFlex align={"center"} className={"flex-1"} gap={2}>
        <Input
          className={"flex-1"}
          onFocus={() => setOpen(true)}
          onBlur={(e) => {
            setOpen(false);
          }}
          placeholder={t("component.contacts.companyName")}
          onChange={(e) => {
            handleInputChange(e);
          }}
          autoComplete={"off"}
          value={value}
        />
        {customerNumber && (
          <ContactTypeComponent
            hasLabel={false}
            type={
              data?.items.find((i) => i.customerNumber === customerNumber)?.type
            }
          />
        )}
        {customerNumber && (
          <span className="text-s text-muted-foreground">{customerNumber}</span>
        )}
      </HorizontalFlex>
      <FormField name={formName} render={() => <FormMessage />} />
      {(open || mouseEnter) && (
        <ScrollArea
          className="absolute left-0 right-0 z-10 mt-1 max-h-32 w-full overflow-y-auto rounded-md border bg-background/30 shadow-lg backdrop-blur-md"
          style={{ position: "absolute", top: "100%", zIndex: 1000 }}
          onMouseEnter={() => setMouseEnter(true)}
          onMouseLeave={() => setMouseEnter(false)}
        >
          {data?.items.map((item: ContactWithId) => (
            <HorizontalFlex
              justify={"between"}
              align={"center"}
              key={item.id}
              onClick={() => {
                onClickItem(item);
              }}
              className="hover:border-1 cursor-pointer border-2 border-dashed px-4 py-2 transition-all duration-300 hover:bg-muted-foreground/30"
            >
              <p className="max-w-96 truncate text-sm font-medium leading-none">
                {item.companyName}
              </p>
              <div className="flex items-center gap-4">
                <p className="text-xs text-muted-foreground">
                  {item.customerNumber}
                </p>
                <ContactTypeComponent type={item.type} hasLabel={false} />
              </div>
            </HorizontalFlex>
          ))}
        </ScrollArea>
      )}
    </div>
  );
};

export default FormContactNameComboBox;
