import React, { FC, ReactNode } from "react";
import { cn } from "@/lib/utils";

export const BottomFloat: FC<{
  children: ReactNode;
  open?: boolean;
  className?: string;
}> = ({ children, open, className }) => {
  if (!open) {
    return null;
  }
  return (
    <div className={"fixed bottom-10 right-20 z-10"}>
      <div
        className={cn("rounded-2xl bg-black/75 p-4 backdrop-blur", className)}
      >
        {children}
      </div>
    </div>
  );
};
