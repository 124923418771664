import React from "react";
import { BiDocument } from "@/models/document";
import { UseFormReturn } from "react-hook-form";
import { z } from "zod";
import { t } from "i18next";
import { biDocumentSchema } from "@/models/EditDocumentModel";
import { FormLabel } from "@/components/ui/form";
import { FormSimpleDate } from "@/components/form/FormSimpleDate";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { ArrowLeftRight } from "lucide-react";

const FormSimpleDateRange = ({
  form,
  document,
  placeholderSingle,
}: {
  form: UseFormReturn<z.infer<typeof biDocumentSchema>>;
  document: BiDocument;
  placeholderSingle?: string;
}) => {
  // show date or period
  const [isDate, setIsDate] = React.useState<boolean>(
    !document.performancePeriodFrom ||
      !document.performancePeriodTo ||
      document.performancePeriodFrom === document.performancePeriodTo,
  );

  return (
    <>
      <HorizontalFlex justify={"start"} align={"center"}>
        <Label className={"w-16"}>
          {isDate
            ? t("model.document.performanceDate")
            : t("model.document.performancePeriod")}
        </Label>
        <div>
          <Button
            variant={"link"}
            className={"text-muted-foreground"}
            onClick={(e) => {
              e.preventDefault();

              form.setValue("performancePeriodTo", null);

              setIsDate((prev) => !prev);
            }}
          >
            <ArrowLeftRight size={14} className={"ml-10 mr-2"} />
            {!isDate
              ? t("model.document.performanceDate")
              : t("model.document.performancePeriod")}
          </Button>
        </div>
      </HorizontalFlex>
      {isDate ? (
        <HorizontalFlex align={"start"}>
          <FormSimpleDate
            form={form}
            name={"performancePeriodFrom"}
            optional={true}
            itemClassName={"flex-1"}
            placeholder={placeholderSingle}
          />
          <div className={"flex-1"} />
        </HorizontalFlex>
      ) : (
        <HorizontalFlex align={"center"} justify={"center"}>
          <FormSimpleDate
            form={form}
            itemClassName={"flex-1"}
            name={"performancePeriodFrom"}
          />
          <FormLabel className={"text-muted-foreground"}>
            {t("model.document.performancePeriodTo")}{" "}
          </FormLabel>
          <FormSimpleDate
            form={form}
            itemClassName={"flex-1"}
            name={"performancePeriodTo"}
          />
        </HorizontalFlex>
      )}
    </>
  );
};

export default FormSimpleDateRange;
