import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { errorToastMiddleware } from "@/api/error";
import { reduxApi } from "@/api/reduxApi";

export const store = configureStore({
  reducer: {
    [reduxApi.reducerPath]: reduxApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware()
      .concat(reduxApi.middleware)
      .concat(errorToastMiddleware);
  },
});

setupListeners(store.dispatch);
