import React from "react";
import { DialogClose, DialogFooter } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useCreateInviteMutation } from "@/api/endpoints/authApi";
import FormErrorDisplay from "@/components/error/FormErrorDisplay";
import { UserCheck } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";
import { useErrorToast } from "@/components/error/useErrorToast";

const registerFormSchema = z.object({
  firstname: z.string().min(2).max(30),
  lastname: z.string().min(2).max(30),
  mail: z.string().email(),
});

type RegisterFormValues = z.infer<typeof registerFormSchema>;

interface InviteUserFormProps {
  close: () => void;
}

const InviteUserForm = ({ close }: InviteUserFormProps) => {
  const { toast } = useToast();
  const { errorToast } = useErrorToast();
  const { t } = useTranslation();

  const [createInvite, createInviteResult] = useCreateInviteMutation();

  const defaultValues: Partial<RegisterFormValues> = {
    firstname: "",
    lastname: "",
    mail: "",
  };

  const form = useForm<RegisterFormValues>({
    resolver: zodResolver(registerFormSchema),
    defaultValues,
    mode: "onBlur",
  });

  const onSubmit = async (data: RegisterFormValues) => {
    await createInvite(data).then((data) => {
      if ("error" in data) {
        errorToast(data.error);
      } else {
        toast({
          icon: UserCheck,
          title: t("common.success"),
          description: t("component.users.dialog.inviteSuccess"),
        });
        form.reset();
        close();
      }
    });
  };
  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col space-y-2"
      >
        {/* NAME */}
        <div className="grid grid-flow-col space-x-4">
          <FormField
            control={form.control}
            name="firstname"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("model.user.firstname")}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="lastname"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("model.user.lastname")}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        {/* MAIL */}
        <FormField
          control={form.control}
          name="mail"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("model.user.mail")}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <DialogFooter>
          {"error" in createInviteResult && (
            <FormErrorDisplay error={createInviteResult.error} />
          )}{" "}
          <DialogClose asChild>
            <Button
              type="button"
              className={"text-xs"}
              variant={
                createInviteResult.isSuccess ? "secondary" : "destructive"
              }
              disabled={
                createInviteResult.isLoading || createInviteResult.isSuccess
              }
            >
              {t("component.users.dialog.cancel")}
            </Button>
          </DialogClose>
          <Button
            type="submit"
            disabled={
              createInviteResult.isLoading || createInviteResult.isSuccess
            }
          >
            {t("component.users.dialog.submit")}
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
};

export default InviteUserForm;
