import { BiDocument, BiDocumentKind } from "@/models/document";
import React from "react";
import {
  FlexOne,
  HorizontalFlex,
  VerticalFlex,
} from "@/components/layout/Flex";
import { formatMoney } from "@/util/format";

interface DocumentResultProps {
  document: BiDocument;
  selected?: boolean;
  onClick?: () => void;
  highlight?: boolean;
}

export const DocumentResult = ({
  document,
  selected,
  onClick,
  highlight,
}: DocumentResultProps) => {
  return (
    <HorizontalFlex
      onClick={onClick}
      className={`relative cursor-pointer select-none items-center justify-center overflow-hidden rounded-[6px] border border-foreground/5 py-2 pl-8 pr-4 text-sm text-foreground transition-colors duration-300 hover:bg-foreground/25 ${selected ? "bg-foreground/20" : ""} ${highlight ? "bg-foreground/5" : ""}`}
    >
      <div
        className={`absolute left-0 top-0 h-full w-4 ${
          document.kind === BiDocumentKind.Invoice
            ? "bg-teal-600"
            : "bg-orange-600"
        }`}
      ></div>
      <VerticalFlex gap={0}>
        <span className={"text-foreground/50"}>{document.invoiceNumber}</span>
        {document.kind == BiDocumentKind.Receipt && (
          <span>{document.issuer.name}</span>
        )}
        {document.kind == BiDocumentKind.Invoice && (
          <span>{document.recipient.name}</span>
        )}
      </VerticalFlex>
      <FlexOne />
      <span className={"font-semibold"}>
        {formatMoney(document.totalAmount)}
      </span>
    </HorizontalFlex>
  );
};
