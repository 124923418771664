import { useTranslation } from "react-i18next";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import React, { useState } from "react";
import { DateRangePicker } from "@/components/ui/date-range-picker";
import { Button } from "@/components/ui/button";
import { DateRange } from "react-day-picker";
import { GLOBAL_API_TOKEN } from "@/api/reduxApi";
import { useLazyGetCheckDatevValidationQuery } from "@/api/endpoints/bookingApi";
import Spinner from "@/components/loading/spinner";
import { formatDate } from "@/util/format";
import { Separator } from "@/components/ui/separator";

interface ExportDatevFormProps {
  close: () => void;
  onValueChange?: (dateRange: DateRange, status: string) => void;
}

export const ExportDatevForm = ({
  close,
  onValueChange,
}: ExportDatevFormProps) => {
  const { t } = useTranslation();

  const [dateRange, setDateRange] = useState<DateRange | undefined>();

  const [datevValidationTrigger, datevValidationTriggerResponse] =
    useLazyGetCheckDatevValidationQuery();

  const [showInvalidContent, setshowInvalidContent] = useState<boolean>(false);

  const handleDownload = () => {
    if (dateRange && dateRange.from && dateRange.to) {
      datevValidationTrigger({
        from: dateRange.from.toISOString(),
        to: dateRange.to.toISOString(),
      }).then((res) => {
        if (res.data && res.data.success) {
          const hiddenElement = document.createElement("a");
          const href: string =
            (process.env.REACT_APP_API_URL ?? "") +
            "datev/export?token=" +
            encodeURIComponent(GLOBAL_API_TOKEN ?? "") +
            "&from=" +
            encodeURIComponent(dateRange.from?.toISOString() ?? "") +
            "&to=" +
            encodeURIComponent(dateRange.to?.toISOString() ?? "");
          hiddenElement.href = href;
          hiddenElement.target = "_blank";
          hiddenElement.click();
        } else {
          setshowInvalidContent(true);
        }
      });
    }
  };

  return (
    <>
      {!showInvalidContent && !datevValidationTriggerResponse.isLoading && (
        <VerticalFlex align="center">
          <Separator />
          <HorizontalFlex className={"w-full"} justify={"center"}>
            <DateRangePicker
              onUpdate={({ range }) => setDateRange(range)}
              preset={"month"}
              align={"center"}
              showCompare={false}
            ></DateRangePicker>
          </HorizontalFlex>
          <Button
            disabled={!dateRange}
            title={t("component.documents.modal.button.download")}
            variant={"outline"}
            className={"flex items-center justify-center"}
            onClick={() => {
              handleDownload();
            }}
          >
            <span>{t("common.download")}</span>
          </Button>
        </VerticalFlex>
      )}
      {showInvalidContent && !datevValidationTriggerResponse.isLoading && (
        <VerticalFlex align="center" gap={8}>
          <Separator />
          <HorizontalFlex
            className={"text-md w-full font-semibold"}
            justify={"center"}
          >
            <span>
              {formatDate(dateRange?.from?.toLocaleDateString()) ?? "-"}
            </span>
            <span className={"text-foreground/30"}>-</span>
            <span>
              {formatDate(dateRange?.to?.toLocaleDateString()) ?? "-"}
            </span>
          </HorizontalFlex>
          <VerticalFlex className={"text-sm"}>
            {datevValidationTriggerResponse.data?.documents?.length === 1 && (
              <span className={"font-thin"}>
                {t("component.documents.modal.hint_one")}
              </span>
            )}
            {datevValidationTriggerResponse.data &&
              datevValidationTriggerResponse.data.documents?.length > 1 && (
                <span className={"font-thin"}>
                  {t("component.documents.modal.hint_more")}
                </span>
              )}

            <ul className={"ml-8 list-disc"}>
              {datevValidationTriggerResponse.data?.documents
                ?.slice(0, 3)
                .map((doc) => (
                  <li key={doc.id}>
                    <HorizontalFlex>
                      <span>Rechnung</span>
                      <span>{doc.invoiceNumber}</span>
                      <span>von</span>
                      <span>{doc.recipient.name}</span>
                    </HorizontalFlex>
                  </li>
                ))}
            </ul>
            {datevValidationTriggerResponse.data?.documents?.length === 4 && (
              <span className={"text-sm text-foreground/70"}>
                ...und ein weiteres
              </span>
            )}
            {datevValidationTriggerResponse.data &&
              datevValidationTriggerResponse.data.documents?.length > 4 && (
                <span className={"text-sm text-foreground/70"}>
                  ...und{" "}
                  {
                    +(
                      datevValidationTriggerResponse.data &&
                      datevValidationTriggerResponse.data.documents?.length - 3
                    )
                  }{" "}
                  weitere
                </span>
              )}
          </VerticalFlex>
          <Separator />
          <Button
            title={t("component.documents.modal.button.complete")}
            variant={"default"}
            className={"flex items-center justify-center"}
            onClick={() => {
              if (onValueChange && dateRange) {
                onValueChange(dateRange, "status_invalid");
              }
              close();
            }}
          >
            <span>{t("common.complete")}</span>
          </Button>
        </VerticalFlex>
      )}
      {datevValidationTriggerResponse.isLoading && (
        <VerticalFlex>
          <Spinner />
        </VerticalFlex>
      )}
    </>
  );
};
