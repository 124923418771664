import React, { useEffect, useState } from "react";
import { Check, Cog, Delete, Settings2 } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useTranslation } from "react-i18next";
import { UserCompany, UserRole } from "@/models/userCompany";
import { useLoggedIn } from "@/feature/auth/ProtectedRoutes";
import { useConfirmModal } from "@/components/modal/useConfirmModal";
import {
  useDismissInviteMutation,
  useEditUserRoleMutation,
} from "@/api/endpoints/authApi";
import { useToast } from "@/components/ui/use-toast";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { t } from "i18next";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { HorizontalFlex } from "@/components/layout/Flex";

interface DropdownActionsProps {
  item: UserCompany;
}

const SelectRole = ({
  role,
  setValue,
}: {
  role: string;
  setValue: (value: string) => void;
}) => {
  return (
    <Select onValueChange={setValue} value={role}>
      <SelectTrigger className={"w-full"}>
        <SelectValue
          placeholder={t("model.userCompany.role.self")}
        ></SelectValue>
      </SelectTrigger>
      <SelectContent>
        <SelectItem value={UserRole.OWNER}>
          {t("model.userCompany.role." + UserRole.OWNER)}
        </SelectItem>
        <SelectItem value={UserRole.NORMAL}>
          {" "}
          {t("model.userCompany.role." + UserRole.NORMAL)}
        </SelectItem>
      </SelectContent>
    </Select>
  );
};

export function DropdownActions({ item }: DropdownActionsProps) {
  const { t } = useTranslation();
  const { confirmDelete } = useConfirmModal();

  const { toast } = useToast();
  const { activeIdentity } = useLoggedIn();

  const [dismissInvite] = useDismissInviteMutation();
  const [editUserRole, editUserRoleResult] = useEditUserRoleMutation();

  const [role, setRole] = useState(item.role as string);
  const [isEditDialogOpen, setEditDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setRole(item.role as string);
  }, [item.role]);

  if (item.id === activeIdentity.id) {
    return null;
  }

  const deleteAction = () => {
    confirmDelete({
      text: t("component.users.dropdown.deleteConfirm", {
        mail: item.user.mail,
      }),
      onAction: async () => {
        let res = await dismissInvite({ userCompanyId: item.id });
        if ("data" in res) {
          toast({
            icon: Check,
            title: t("common.success"),
            description: t("component.users.dropdown.deleteSuccess"),
          });
        }
      },
    });
  };

  const editUserAction = () => {
    setEditDialogOpen(true);
  };

  const handleEdit = async () => {
    setLoading(true);
    await editUserRole({
      userCompanyId: item.id,
      role: role,
    });
    if (editUserRoleResult.isSuccess) {
      toast({
        icon: Check,
        title: t("common.success"),
        description: t("component.users.dropdown.editUserSuccess"),
      });
    }
    setLoading(false);
    setEditDialogOpen(false);
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost">
            <Settings2 size={16} />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuGroup>
            <DropdownMenuItem className="text-red-500" onClick={deleteAction}>
              <Delete className="mr-2 h-4 w-4" />
              <span>{t("component.users.dropdown.delete")}</span>
            </DropdownMenuItem>
            <DropdownMenuItem onClick={editUserAction}>
              <Cog className="mr-2 h-4 w-4" />
              <span>{t("component.users.dropdown.editUser")}</span>
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>

      <Dialog open={isEditDialogOpen} onOpenChange={setEditDialogOpen}>
        <DialogTrigger asChild>
          <Button variant="ghost" onClick={editUserAction}>
            {t("component.users.dropdown.editUser")}
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {t("component.users.dropdown.editUserConfirmTitle")}
            </DialogTitle>
            <DialogDescription>
              {t("component.users.dropdown.editUserConfirm", {
                mail: item.user.mail,
              })}
            </DialogDescription>
          </DialogHeader>
          <div className={"grid grid-cols-2 space-x-4"}>
            <SelectRole role={role} setValue={setRole} />
          </div>
          <HorizontalFlex>
            <Button variant="ghost" onClick={() => setEditDialogOpen(false)}>
              {t("component.users.dropdown.cancel")}
            </Button>
            <Button variant="default" onClick={handleEdit} disabled={loading}>
              {t("component.users.dropdown.save")}
            </Button>
          </HorizontalFlex>
        </DialogContent>
      </Dialog>
    </>
  );
}
