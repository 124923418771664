export interface CenterProps {
  children?: React.ReactNode;
  h?: boolean;
  v?: boolean;
  className?: string;
}

export const Center: React.FC<CenterProps> = (props) => {
  const { children, h, v, className } = props;
  return (
    <div
      className={`flex justify-${h ? "center" : "start"} items-${
        v ? "center" : "start"
      } flex-1 ${className}`}
    >
      {children}
    </div>
  );
};
