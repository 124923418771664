import { reduxApi } from "../reduxApi";
import { User } from "@/models/user";
import { Auth } from "@/api/types";
import { Company } from "@/models/company";
import { UserCompany } from "@/models/userCompany";

const authApi = reduxApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<Auth, { mail: string; password: string }>({
      query: (body) => ({
        url: "auth/login",
        method: "POST",
        body: body,
      }),
    }),
    register: builder.mutation<
      {
        token: string;
        user: User;
        company: Company;
        identities: UserCompany[];
        inviteCode?: string;
      },
      {
        companyName: string;
        firstname: string;
        lastname: string;
        mail: string;
        phone: string;
        password: string;
      }
    >({
      query: (body) => ({
        url: "onboarding/create",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["User"],
    }),
    forgot: builder.mutation<void, { mail: string }>({
      query: (body) => ({
        url: "onboarding/password/forgot",
        method: "POST",
        body: body,
      }),
    }),
    reset: builder.mutation<Auth, { newPassword: string; token: string }>({
      query: (body) => ({
        url: "onboarding/password/reset",
        method: "POST",
        body: body,
      }),
    }),
    // accept invite
    acceptInvite: builder.mutation<
      Auth & { identity: UserCompany },
      { token: string }
    >({
      query: (body) => ({
        url: "onboarding/invite/accept",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["User"],
    }),
    // create invite
    createInvite: builder.mutation<
      string,
      { firstname: string; lastname: string; mail: string }
    >({
      query: (body) => ({
        url: "onboarding/invite/create",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["User"],
    }),

    // dismiss invite
    dismissInvite: builder.mutation<string, { userCompanyId: string }>({
      query: (body) => ({
        url: "onboarding/invite/dismiss",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["User"],
    }),

    // edit role
    editUserRole: builder.mutation<
      string,
      { userCompanyId: string; role: string }
    >({
      query: (args) => ({
        url: `onboarding/user/${args.userCompanyId}/role`,
        method: "POST",
        body: { role: args.role },
      }),
      invalidatesTags: ["User"],
    }),
    createCompany: builder.mutation<
      Company,
      {
        companyName: string;
        phone: string;
      }
    >({
      query: (body) => ({
        url: "onboarding/addcompany",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["User"],
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useForgotMutation,
  useResetMutation,
  useAcceptInviteMutation,
  useCreateInviteMutation,
  useDismissInviteMutation,
  useEditUserRoleMutation,
  useCreateCompanyMutation,
} = authApi;
