import React from "react";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { PhoneInput } from "@/components/ui/phone-input";
import { UseFormReturn } from "react-hook-form";
import { Contact } from "@/models/contact";
import { t } from "i18next";
import { Textarea } from "@/components/ui/textarea";

const ContactInfoFormFields = ({ form }: { form: UseFormReturn<Contact> }) => {
  return (
    <div className={"grid grid-cols-2 gap-4"}>
      <FormField
        control={form.control}
        name="contactInfo.contactPersonName"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t("component.contacts.contactPersonName")}</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>

            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="contactInfo.email"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t("component.contacts.email")}</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>

            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="contactInfo.phone"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t("component.contacts.phone")}</FormLabel>
            <FormControl>
              <PhoneInput {...field} />
            </FormControl>

            <FormMessage />
          </FormItem>
        )}
      />{" "}
      <FormField
        control={form.control}
        name="contactInfo.website"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t("component.contacts.website")}</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>

            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="contactInfo.notes"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t("component.contacts.notes")}</FormLabel>
            <FormControl>
              <Textarea {...field} />
            </FormControl>

            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
};

export default ContactInfoFormFields;
