import { SimpleDate } from "@/models/document";

export function getMonthsBetweenDates(d1: Date, d2: Date) {
  // Written by ChatGPT

  // Calculate the year and month difference
  const yearsDifference = d2.getFullYear() - d1.getFullYear();
  const monthsDifference = d2.getMonth() - d1.getMonth();

  // Calculate the difference in days
  const daysDifference = d2.getDate() - d1.getDate() + 1;

  // Total months difference without considering partial month
  let totalMonths = yearsDifference * 12 + monthsDifference;

  // Calculate the number of days in the month of the first date
  const daysInMonth1 = new Date(
    d2.getFullYear(),
    d2.getMonth() + 1,
    0,
  ).getDate();

  // Calculate the fraction of the month from the days difference
  const partialMonth = daysDifference / daysInMonth1;

  return totalMonths + partialMonth;
}

// Function to calculate the number of days between two dates
export function getDaysBetweenDates(date1: Date, date2: Date) {
  // Written by ChatGPT
  // Calculate the difference in time
  const diffTime = Math.abs(date1.getTime() - date2.getTime());

  // Convert time difference from milliseconds to days
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}

export function listDaysBetweenDates(date1: Date, date2: Date): Date[] {
  const startDate = startOfDay(date1);
  const endDate = startOfDay(date2);
  const dateArray: Date[] = [];
  let currentDate = startDate;

  while (currentDate <= endDate) {
    dateArray.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dateArray;
}

export function listMonthsBetweenDates(date1: Date, date2: Date): Date[] {
  const startDate = startOfMonth(date1);
  const endDate = startOfMonth(date2);
  const dateArray: Date[] = [];
  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    dateArray.push(new Date(currentDate));
    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return dateArray;
}

export function listWeeksBetweenDates(date1: Date, date2: Date): Date[] {
  const startDate = startOfWeek(date1);
  const endDate = startOfWeek(date2);
  const dateArray: Date[] = [];
  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    dateArray.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 7);
  }

  return dateArray;
}

export function startOfWeek(date: Date): Date {
  const start = new Date(date);
  const day = (start.getDay() + 1) % 7;
  const diff = start.getDate() - day; // Subtracting the current day to get the previous Sunday
  start.setDate(diff);
  start.setHours(0, 0, 0, 0);
  return start;
}

export function endOfWeek(date: Date): Date {
  let end = startOfWeek(date);
  end.setDate(end.getDate() + 6);
  end.setHours(23, 59, 59, 999);
  return end;
}

export function startOfDay(date: Date): Date {
  const start = new Date(date);
  start.setHours(0, 0, 0, 0);
  return start;
}

export function endOfDay(date: Date): Date {
  const end = new Date(date);
  end.setHours(23, 59, 59, 999);
  return end;
}

export function startOfMonth(date: Date): Date {
  const start = new Date(date);
  start.setDate(1);
  start.setHours(0, 0, 0, 0);
  return start;
}

export function endOfMonth(date: Date): Date {
  // Create a new Date object based on the given date
  const end = new Date(date);

  // Move to the first day of the next month
  end.setMonth(end.getMonth() + 1);
  end.setDate(1);

  // Subtract one millisecond to get the last moment of the current month
  end.setMilliseconds(end.getMilliseconds() - 1);

  return end;
}

export function getISOWeek(date: Date) {
  const tempDate = new Date(date);
  tempDate.setHours(0, 0, 0, 0);
  tempDate.setDate(tempDate.getDate() + 3 - ((tempDate.getDay() + 6) % 7));
  const week1 = new Date(tempDate.getFullYear(), 0, 4);
  return (
    1 +
    Math.round(
      ((tempDate.getTime() - week1.getTime()) / 86400000 -
        3 +
        ((week1.getDay() + 6) % 7)) /
        7,
    )
  );
}

export function convertSimpleDate(date: SimpleDate): Date {
  return new Date(date.year, date.month - 1, date.day, 12);
}

export function convertDate(date: Date | undefined): SimpleDate {
  if (!date) {
    return { year: 1900, month: 1, day: 1 };
  }
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
  };
}
