import {
  DataTable,
  DataTableColumn,
  HasId,
  Sort,
} from "@/components/display/DataTable";
import { Path, traverse } from "@/util/DeepProps";
import * as React from "react";
import { ReactNode, useMemo, useState } from "react";
import MultipleSelector from "@/components/ui/multiple-selector";

export interface DataLocalTableColumn<T extends HasId>
  extends DataTableColumn<T, Path<T>> {
  extractSearchText?: (data: T) => string;
  extractSearchTag?: (data: T) => {
    label: string;
    value: string;
  }[];
}

export const DataLocalTable = <T extends HasId>(props: {
  data: T[];
  columns: DataLocalTableColumn<T>[];
  extraTopRight?: ReactNode;
  extraTopLeft?: ReactNode;
}) => {
  const [sortKey, setSortKey] = React.useState<Sort<Path<T>>>(null);
  const [search, setSearch] = useState<string>("");
  const [searchTags, setSearchTags] = useState<string[]>([]);

  let availableTags = useMemo(() => {
    let tags = props.data.flatMap((item) =>
      props.columns.flatMap(
        (c) =>
          c.extractSearchTag?.(item)?.map((v) => ({
            label: v.label,
            value: v.value,
            header: typeof c.header === "string" ? c.header : undefined,
          })) ?? [],
      ),
    );

    let map = new Map<
      string,
      {
        label: string;
        value: string;
        header: string | undefined;
      }
    >();
    for (let tag of tags) {
      map.set(tag.value, tag);
    }

    return Array.from(map.values());
  }, [props.data]);

  let sortedData = useMemo(() => {
    let sorted = [...props.data];
    if (sortKey) {
      sorted.sort((a, b) => {
        let aKey = traverse(a, sortKey[0]);
        let bKey = traverse(b, sortKey[0]);

        if (aKey < bKey) {
          return sortKey[1] === "asc" ? -1 : 1;
        } else if (aKey > bKey) {
          return sortKey[1] === "asc" ? 1 : -1;
        } else {
          return 0;
        }
      });
    }
    return sorted;
  }, [props.data, sortKey]);

  let filteredData = useMemo(() => {
    if (!search && !searchTags.length) return sortedData;

    let words = search.split(/\s+/).map((s) => s.toLowerCase());

    return sortedData.filter((item) => {
      let dataText = props.columns
        .map((c) => c.extractSearchText?.(item)?.toLowerCase() ?? "")
        .join(" ");

      let dataTags = props.columns
        .flatMap((c) => c.extractSearchTag?.(item) ?? [])
        .map((t) => t.value);

      // return false if not all words are included.
      for (let tag of searchTags) {
        if (!dataTags.includes(tag)) {
          return false;
        }
      }

      // return false if not all words are included.
      for (let word of words) {
        if (!dataText.includes(word)) {
          return false;
        }
      }

      return true;
    });
  }, [sortedData, search, JSON.stringify(searchTags)]);

  return (
    <>
      <div className="flex items-start py-4">
        {props.extraTopLeft}
        <MultipleSelector
          placeholder="Suche"
          className="flex-1"
          groupBy={"header"}
          showGroupInBadge
          options={availableTags}
          onChange={(op) => {
            console.log(op);
            setSearchTags(op.map((o) => o.value));
          }}
          inputProps={{
            onValueChange: setSearch,
          }}
        />
        {props.extraTopRight}
      </div>

      <DataTable
        columns={props.columns}
        data={filteredData}
        onSortChange={setSortKey}
      />
    </>
  );
};
