import { FormControl, FormField, FormItem } from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import React, { ReactNode } from "react";
import { Control, FieldPath } from "react-hook-form/dist/types";
import { FieldValues } from "react-hook-form/dist/types/fields";

export interface FormSimpleSelectOption {
  value: string;
  label: ReactNode;
}

export interface FormSimpleSelectGroup {
  label: string;
  options: FormSimpleSelectOption[];
}

export const FormSimpleSelect = <
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
>(props: {
  edit?: boolean;
  form: { control: Control<T> };
  name: TName;
  options: FormSimpleSelectGroup[];
  placeholder?: string;
  invalidPlaceholder?: ReactNode;
  label?: ReactNode;
}) => {
  return (
    <FormField
      control={props.form.control}
      name={props.name}
      render={({ field }) => {
        const findSelectedOption = (
          options: FormSimpleSelectGroup[],
          value: string,
        ) => {
          for (const group of options) {
            const option = group.options.find((f) => f.value === value);
            if (option) {
              return option.label;
            }
          }
          return null;
        };

        if (!props.edit) {
          const selectedLabel = field.value
            ? findSelectedOption(props.options, field.value)
            : null;
          return (
            <span className={selectedLabel ? "" : "opacity-50"}>
              {selectedLabel || props.placeholder}
            </span>
          );
        }

        return (
          <FormItem>
            <Select
              {...field}
              onValueChange={field.onChange}
              defaultValue={field.value}
            >
              <FormControl>
                <SelectTrigger className={"w-full"}>
                  {props.invalidPlaceholder && (
                    <div className="text-sm">{props.invalidPlaceholder}</div>
                  )}
                  {!props.invalidPlaceholder &&
                    !field.value &&
                    props.placeholder && (
                      <div className="text-muted-foreground">
                        {props.placeholder}
                      </div>
                    )}
                  <SelectValue />
                </SelectTrigger>
              </FormControl>
              <SelectContent>
                {props.options.map((group, index) => (
                  <SelectGroup
                    key={group.label + index}
                    className="mb-4 divide-accent-foreground border-muted-foreground bg-background p-2"
                  >
                    <SelectLabel className="font-semibold text-muted-foreground">
                      {group.label}
                    </SelectLabel>
                    {group.options.map((o, index2) => (
                      <SelectItem
                        value={o.value}
                        key={o.value + index + index2}
                      >
                        {o.label}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                ))}
              </SelectContent>
            </Select>
          </FormItem>
        );
      }}
    />
  );
};
