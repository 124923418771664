import * as React from "react";
import { useMemo } from "react";
import { UserCompany, UserRole } from "@/models/userCompany";
import ProfileAvatar from "@/components/user/profile-avatar";
import {
  DataLocalTable,
  DataLocalTableColumn,
} from "@/components/display/DataLocalTable";
import { useTranslation } from "react-i18next";
import { DropdownActions } from "@/feature/users/components/DropdownActions";
import { useLoggedIn } from "@/feature/auth/ProtectedRoutes";
import { Button } from "@/components/ui/button";
import InviteUserForm from "@/feature/users/components/InviteUserForm";
import { useModal } from "@/components/modal/useModal";

type UserTableProps = {
  data: UserCompany[];
};

export default function UserTable({ data }: UserTableProps) {
  const { t } = useTranslation();
  const { activeIdentity } = useLoggedIn();

  const { showModal } = useModal();

  const showInviteModal = () => {
    showModal({
      title: t("component.users.dialog.title"),
      description: t("component.users.dialog.description"),
      content: ({ close }) => <InviteUserForm close={close} />,
    });
  };

  const isOwner = activeIdentity.role === UserRole.OWNER;

  let columns: DataLocalTableColumn<UserCompany>[] = useMemo(
    () => [
      {
        sortKey: "id",
        cell: (item) => <ProfileAvatar user={item.user} />,
      },
      {
        sortKey: "user.firstname",
        header: t("model.user.firstname"),
        cell: (item) => item.user.firstname,
        extractSearchText: (item) => item.user.firstname,
      },
      {
        sortKey: "user.lastname",
        header: t("model.user.lastname"),
        cell: (item) => item.user.lastname,
        extractSearchText: (item) => item.user.lastname,
      },
      {
        sortKey: "user.mail",
        header: t("model.user.mail"),
        cell: (item) => item.user.mail,
        extractSearchText: (item) => item.user.mail,
      },
      {
        sortKey: "status",
        header: t("model.userCompany.status.self"),
        extractSearchTag: (item) => [
          {
            label: t("model.userCompany.status." + item.status),
            value: item.status,
          },
        ],
        cell: (item) => t("model.userCompany.status." + item.status),
      },
      {
        sortKey: "role",
        header: t("model.userCompany.role.self"),
        extractSearchTag: (item) => [
          {
            label: t("model.userCompany.role." + item.role),
            value: item.role,
          },
        ],
        cell: (item) => t("model.userCompany.role." + item.role),
      },
      ...(isOwner
        ? [
            {
              cell: (item) => <DropdownActions item={item} />,
            } as DataLocalTableColumn<UserCompany>,
          ]
        : []),
    ],
    [t],
  );

  return (
    <DataLocalTable
      columns={columns}
      data={data}
      extraTopRight={
        isOwner ? (
          <Button className={"ml-2"} onClick={showInviteModal}>
            {t("component.users.dialog.trigger")}
          </Button>
        ) : undefined
      }
    />
  );
}
