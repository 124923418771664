import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";

import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { useUpdateUserMutation } from "@/api/endpoints/userApi";
import { User } from "@/models/user";
import { useErrorToast } from "@/components/error/useErrorToast";
import { useTranslation } from "react-i18next";
import { Check } from "lucide-react";
import FormErrorDisplay from "@/components/error/FormErrorDisplay";

const profileFormSchema = z.object({
  firstname: z.string().min(2).max(30),
  lastname: z.string().min(2).max(30),
  mail: z.string().email(),
});

type ProfileFormValues = z.infer<typeof profileFormSchema>;

export function ProfileForm({ user }: { user: User }) {
  const { t } = useTranslation();

  const { toast } = useToast();
  const { errorToast } = useErrorToast();
  const [updateUser, status] = useUpdateUserMutation();

  const defaultValues: Partial<ProfileFormValues> = {
    firstname: user.firstname,
    lastname: user.lastname,
    mail: user.mail,
    // image: "",
  };
  const form = useForm<ProfileFormValues>({
    resolver: zodResolver(profileFormSchema),
    defaultValues,
    mode: "onChange",
  });

  const onSubmit = async (data: ProfileFormValues) => {
    await updateUser(data).then((data) => {
      if ("error" in data) {
        errorToast(data.error);
      } else {
        toast({
          icon: Check,
          title: t("common.success"),
          description: t("component.settings.profile.success"),
        });
      }
    });
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <div className="grid grid-flow-col space-x-4">
          <FormField
            control={form.control}
            name="firstname"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("model.user.firstname")}</FormLabel>
                <FormControl>
                  <Input placeholder="shadcn" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="lastname"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("model.user.lastname")}</FormLabel>
                <FormControl>
                  <Input placeholder="shadcn" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <FormField
          control={form.control}
          name="mail"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("model.user.mail")}</FormLabel>
              <FormControl>
                <Input placeholder="shadcn" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {"error" in status && <FormErrorDisplay error={status.error} />}
        <Button type="submit" disabled={status.isLoading}>
          {t("common.saveChanges")}
        </Button>
      </form>
    </Form>
  );
}
