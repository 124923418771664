import React from "react";

type DocumentContextType = {
  selectedDocumentId: string | undefined;
  selectDocumentById: (id: string | undefined) => void;
  selectNextDocument: () => void;
  selectPreviousDocument: () => void;
  mode: "small" | "preview" | "edit" | "edit-saved";
  setMode: (mode: DocumentContextType["mode"]) => void;
};

export const DocumentContext = React.createContext<DocumentContextType>({
  selectedDocumentId: undefined,
  selectDocumentById: () => {
    console.error("selectDocumentById not implemented");
  },
  selectNextDocument: () => {
    console.error("selectNextDocument not implemented");
  },
  selectPreviousDocument: () => {
    console.error("selectPreviousDocument not implemented");
  },
  mode: "small",
  setMode: () => {
    console.error("setMode not implemented");
  },
});

export const useDocumentContext = () => {
  const context = React.useContext(DocumentContext);
  !context &&
    console.error(
      "useDocumentContext must be used within a DocumentContextProvider",
    );
  return context;
};
