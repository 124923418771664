import React from "react";
import {
  FlexOne,
  HorizontalFlex,
  VerticalFlex,
} from "@/components/layout/Flex";
import { Title } from "@/components/text/Title";
import { Button } from "@/components/ui/button";
import { Crown, Factory, HeartHandshake, UserPlus } from "lucide-react";
import { t } from "i18next";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { CreateContactForm } from "@/feature/contacts/form/ContactForm";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import ContactTable from "@/feature/contacts/tables/ContactTable";
import { Contact, ContactType } from "@/models/contact";
import { useFilterContactsQuery } from "@/api/endpoints/contactsApi";
import Spinner from "@/components/loading/spinner";

function AddContactModal() {
  const [open, setOpen] = React.useState(false);
  const { data, isLoading } = useFilterContactsQuery({
    limit: 1000,
    offset: 0,
  });
  if (isLoading) {
    return <Spinner />;
  }

  const allContacts = data?.items as Contact[];
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant={"default"}>
          <span className={"flex flex-row items-center gap-2"}>
            <UserPlus />
            {t("component.contacts.modal.title")}
          </span>
        </Button>
      </DialogTrigger>
      <DialogContent
        className={"max-w-[50vw] overflow-y-auto"}
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
      >
        <CreateContactForm
          allContacts={allContacts}
          onSuccess={() => {
            setOpen(false);
          }}
        />
      </DialogContent>
    </Dialog>
  );
}

export const ContactTypeComponent: React.FC<{
  type?: ContactType;
  hasLabel?: boolean;
}> = ({ type, hasLabel = true }) => {
  const renderIcon = () => {
    switch (type) {
      case "customer":
        return <Crown size={16} className={"text-yellow-200/70"} />;
      case "supplier":
        return <Factory size={16} className={"text-green-200/70"} />;
      case "partner":
        return <HeartHandshake size={16} className={"text-red-200/70"} />;
      default:
        return <HeartHandshake size={16} className={"text-red-200/70"} />;
    }
  };

  return (
    <HorizontalFlex gap={2}>
      <>
        {renderIcon()}
        {hasLabel && t(`component.contacts.type.${type}`)}
      </>
    </HorizontalFlex>
  );
};

const Contacts = () => {
  return (
    <VerticalFlex gap={8}>
      <HorizontalFlex className="pb-1">
        <Title>{t("component.contacts.main.title")}</Title>
        <FlexOne />
        <AddContactModal />
      </HorizontalFlex>
      <Tabs defaultValue={"all"}>
        <TabsList>
          <TabsTrigger value={"all"}>
            {t("component.contacts.type.all")}
          </TabsTrigger>
          <TabsTrigger value={"customer"}>
            <ContactTypeComponent type={ContactType.Customer} />
          </TabsTrigger>{" "}
          <TabsTrigger value={"supplier"}>
            <ContactTypeComponent type={ContactType.Supplier} />
          </TabsTrigger>
          <TabsTrigger value={"partner"}>
            <ContactTypeComponent type={ContactType.Partner} />
          </TabsTrigger>
        </TabsList>
        {/*content will be same table component just with different data, but still 4 different components, if there should be an extra button in future*/}
        <TabsContent value={"all"}>
          <ContactTable />
        </TabsContent>
        <TabsContent value={"customer"}>
          <ContactTable type={ContactType.Customer} />
        </TabsContent>
        <TabsContent value={"supplier"}>
          <ContactTable type={ContactType.Supplier} />
        </TabsContent>
        <TabsContent value={"partner"}>
          <ContactTable type={ContactType.Partner} />
        </TabsContent>
      </Tabs>
    </VerticalFlex>
  );
};

export default Contacts;
