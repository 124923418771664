import { FC } from "react";
import {
  FlexOne,
  HorizontalFlex,
  VerticalFlex,
} from "@/components/layout/Flex";
import { useListGoCardlessRequisitionsQuery } from "@/api/endpoints/bankApi";
import Spinner from "@/components/loading/spinner";
import { EmptyCard } from "@/feature/settings/BankSettings/components/EmptyCard";
import { RequisitionCard } from "@/feature/settings/BankSettings/components/RequisitionCard";
import { AddBankButton } from "@/components/bank/AddBankButton";

const BankSettingsContent: FC<{}> = () => {
  const { data: requisitionList, isLoading: requisitionListisLoading } =
    useListGoCardlessRequisitionsQuery();

  return (
    <VerticalFlex>
      <HorizontalFlex>
        <FlexOne />
        {requisitionList?.length !== 0 && <AddBankButton />}
      </HorizontalFlex>
      {requisitionListisLoading && <Spinner />}
      {requisitionList?.length === 0 && <EmptyCard />}
      {requisitionList?.map((r) => {
        return <RequisitionCard requisition={r} key={r.id} />;
      })}
    </VerticalFlex>
  );
};

export default BankSettingsContent;
