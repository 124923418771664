import { BankAccount } from "@/models/bankAccount";

export interface GoCardlessRequisition {
  id: string;
  userId: string;
  lastSync?: string;
  goCardlessId: string;
  redirect: string;
  status: GoCardlessRequisitionStatus;
  goCardlessInstitutionId: string;
  agreementId: string;
  link: string;
  userLanguage: string;
  ssn: string;
  accountSelection: boolean;
  redirectImmediate: boolean;
  bankAccounts: BankAccount[];
  expiration?: string;
}

export enum GoCardlessRequisitionStatus {
  CR = "CR",
  GC = "GC",
  UA = "UA",
  RJ = "RJ",
  SA = "SA",
  GA = "GA",
  LN = "LN",
  EX = "EX",
}
