import { isRejectedWithValue } from "@reduxjs/toolkit";
import type { MiddlewareAPI, Middleware } from "@reduxjs/toolkit";
import { toast } from "@/components/ui/use-toast";
import { formatError } from "@/components/error/format";
import { CircleX } from "lucide-react";
import { useEffect } from "react";

let enabled = true;

/**
 * Log a warning and show a toast!
 */
export const errorToastMiddleware: Middleware =
  (_: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (enabled && isRejectedWithValue(action)) {
      toast({
        title: formatError(action.payload),
        icon: CircleX,
      });
    }
    return next(action);
  };

export const useErrorToastMiddleware = () => {
  useEffect(() => {
    enabled = true;
    return () => {
      enabled = false;
    };
  }, []);
};
