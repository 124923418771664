// doc FormSimpleMoney.tsx
import { FieldValues } from "react-hook-form/dist/types/fields";
import {
  Control,
  ControllerRenderProps,
  FieldPath,
} from "react-hook-form/dist/types";
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";

import React, { useEffect } from "react";
import { cn } from "@/lib/utils";

export interface FormSimpleMoneyProps<
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
> {
  edit?: boolean;
  form: { control: Control<T> };
  name: TName;
  className?: string;
  displayClassName?: string;
  itemClassName?: string;
}

export const FormSimpleMoney = <
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
>(
  props: FormSimpleMoneyProps<T, TName>,
) => {
  return (
    <FormField
      control={props.form.control}
      name={props.name}
      render={({ field }) => <InnerMoney {...props} field={field} />}
    />
  );
};

export const InnerMoney = <
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
>({
  field,
  ...props
}: FormSimpleMoneyProps<T, TName> & {
  field: ControllerRenderProps<T, TName>;
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [focus, setFocus] = React.useState(false);
  const [value, setValue] = React.useState("");

  useEffect(() => {
    if (!focus) {
      if (field.value === 0 || field.value === null) {
        setValue("");
      } else {
        setValue(formatNumberSimple(field.value));
      }
    }
  }, [focus, value]);

  if (props.edit === false) {
    return (
      <span
        className={cn(
          "font-semibold",
          props.displayClassName,
          field.value < 0 ? "text-red-300" : "",
        )}
      >
        {formatNumberFancy(field.value)} €
      </span>
    );
  }

  return (
    <FormItem className={props.itemClassName}>
      <FormControl>
        <div
          onClick={() => {
            inputRef.current?.focus();
          }}
          className={cn(
            "flex h-9 rounded-md border border-input bg-transparent px-3 py-1 align-baseline text-sm shadow-sm transition-colors",
            focus ? "border-primary" : "border-input",
            props.className,
          )}
        >
          <input
            ref={inputRef}
            className={cn(
              "border-none bg-transparent text-right outline-none placeholder:text-muted-foreground",
              field.value < 0 ? "text-red-300" : "",
              props.className,
            )}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            value={focus ? value || "" : formatNumberFancy(field.value)}
            onChange={(e) => {
              let value = e.target.value.replaceAll(/[^0-9,\-]/g, "");
              setValue(value);
              field.onChange(parseNumber(value));
            }}
          />
          <span
            className={cn("ml-3 pt-0.5", field.value < 0 ? "text-red-300" : "")}
          >
            {"€"}
          </span>
        </div>
      </FormControl>
      <FormMessage />
    </FormItem>
  );
};

function formatNumberSimple(value: number) {
  return Intl.NumberFormat("de-DE", {
    useGrouping: false,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(value / 100);
}

export function formatNumberFancy(value: number | null) {
  if (value === null) {
    return "";
  }
  return Intl.NumberFormat("de-DE", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(value / 100);
}

function parseNumber(value: string) {
  // Int is needed, else backend will complain
  const v = Math.round(parseFloat(value.replaceAll(",", ".")) * 100);
  if (isNaN(v)) {
    return 0;
  }
  return v;
}
