import { Button } from "@/components/ui/button";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReloadIcon } from "@radix-ui/react-icons";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { HorizontalFlex } from "@/components/layout/Flex";

export const SaveBar: FC<{
  className?: string;
  result: {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    reset: () => void;
  };
  onAbort?: () => void;
  saveAction?: () => void;
}> = ({ result, onAbort, className, saveAction }) => {
  const { t } = useTranslation();
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    if (result.isLoading) {
      setShowLoading(true);
    } else {
      let timeout = setTimeout(() => {
        setShowLoading(false);
      }, 1000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [result.isLoading]);

  return (
    <HorizontalFlex className={className} justify={"center"}>
      <Tooltip>
        <TooltipTrigger asChild={true}>
          <Button
            type="reset"
            className="hover:bg-red-900"
            variant={"ghost"}
            onClick={() => {
              result.reset();
              onAbort && onAbort();
            }}
          >
            {t("common.abort")}
          </Button>
        </TooltipTrigger>
        <TooltipContent>{t("common.abort")}</TooltipContent>
      </Tooltip>
      <Tooltip>
        <TooltipTrigger asChild={true}>
          <Button
            type="submit"
            variant={"default"}
            disabled={showLoading}
            onClick={() => {
              saveAction && saveAction();
            }}
          >
            {t("common.save")}
            {showLoading && (
              <ReloadIcon className="ml-2 h-4 w-4 animate-spin" />
            )}
          </Button>
        </TooltipTrigger>
        <TooltipContent>{t("common.save")}</TooltipContent>
      </Tooltip>
    </HorizontalFlex>
  );
};
