import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";

import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";

import { PhoneInput } from "@/components/ui/phone-input";
import { useRegisterMutation } from "@/api/endpoints/authApi";
import { useAuth } from "@/feature/auth/AuthProvider";
import { useTranslation } from "react-i18next";
import { FormSimpleText } from "@/components/form/FormSimpleText";
import { Separator } from "@radix-ui/react-menu";
import FormErrorDisplay from "@/components/error/FormErrorDisplay";

const registerFormSchema = z.object({
  companyName: z.string().min(4),
  firstname: z.string().min(2).max(30),
  lastname: z.string().min(2).max(30),
  mail: z.string().email(),
  phone: z.string(),
  password: z.string().min(8),
  inviteCode: z.string().optional(),
});

type RegisterFormValues = z.infer<typeof registerFormSchema>;

export function RegisterForm() {
  const navigation = useNavigate();
  const auth = useAuth();
  const [register, registerResult] = useRegisterMutation();
  const { t } = useTranslation();

  const defaultValues: Partial<RegisterFormValues> = {
    companyName: "",
    firstname: "",
    lastname: "",
    mail: "",
    phone: "",
    password: "",
    inviteCode: "",
  };

  const form = useForm<RegisterFormValues>({
    resolver: zodResolver(registerFormSchema),
    defaultValues,
    mode: "onBlur",
  });

  const onSubmit = async (data: RegisterFormValues) => {
    await register(data).then((data) => {
      if ("data" in data) {
        auth.login(data.data.token, data.data.company);
        navigation("/");
      }
    });
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col space-y-2"
      >
        {/* COMPANY */}
        <FormSimpleText
          form={form}
          name={"companyName"}
          label={t("model.company.name")}
        />

        {/* NAME */}
        <div className="grid grid-flow-col space-x-4">
          <FormSimpleText
            form={form}
            name={"firstname"}
            label={t("model.user.firstname")}
          />
          <FormSimpleText
            form={form}
            name={"lastname"}
            label={t("model.user.lastname")}
          />
        </div>

        {/* MAIL */}
        <FormSimpleText
          form={form}
          name={"mail"}
          type={"email"}
          label={t("model.user.mail")}
        />

        {/* PHONE */}
        <FormField
          control={form.control}
          name="phone"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("model.company.phone")}</FormLabel>
              <FormControl>
                <PhoneInput {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {/* PASSWORD */}
        <FormSimpleText
          form={form}
          name={"password"}
          type={"password"}
          label={t("model.user.password")}
        />

        <div className={"mt-12"} />
        <Separator className={"border-t-2 border-accent"} />

        {/* Invite Code */}
        <FormSimpleText
          form={form}
          name={"inviteCode"}
          label={t("model.company.inviteCode")}
        />

        <FormErrorDisplay error={registerResult.error} />

        <div className="flex justify-center pt-8">
          <Button type="submit" className="mx-auto w-full max-w-xs">
            {t("component.auth.register.registerAction")}
          </Button>
        </div>
      </form>
    </Form>
  );
}
