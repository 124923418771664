import { FC } from "react";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { useTranslation } from "react-i18next";
import { AddBankButton } from "@/components/bank/AddBankButton";

export const EmptyCard: FC<{}> = () => {
  const { t } = useTranslation();

  return (
    <Card className={"max-w-2xl"}>
      <CardHeader>
        <CardTitle>{t("component.bankSettings.emptyCard.title")}</CardTitle>
      </CardHeader>
      <CardContent>{t("component.bankSettings.emptyCard.text")}</CardContent>
      <CardFooter>
        <AddBankButton />
      </CardFooter>
    </Card>
  );
};
