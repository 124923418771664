import React, { useCallback, useMemo } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { formatMoney } from "@/util/format";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";

export type NumberCardProps = {
  title: string;
  totalAmount?: number;
  subtext?: string;
  formatter?: (totalAmount: number) => string;
  change?: number;
  isLoading?: boolean;
  icon: React.ReactNode;
  tooltipContent?: string;
};

const NumberCard: React.FC<NumberCardProps> = ({
  title,
  totalAmount = 0,
  subtext,
  formatter = formatMoney,
  icon,
  tooltipContent,
  isLoading = false,
}) => {
  const formatterFn = useCallback(formatter, [formatter]);
  const formattedTotalAmount = useMemo(() => {
    return formatterFn(totalAmount)?.replace("-", "(-)");
  }, [totalAmount, formatterFn]);

  return (
    <Card className="mx-auto w-full">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        {isLoading ? (
          <Skeleton className="h-4 w-full max-w-[250px]" />
        ) : (
          <div className="flex w-full flex-row items-center justify-between">
            <CardTitle className="text-md mb-0 items-center font-semibold">
              {title}
              {subtext && (
                <div className="text-xs text-muted-foreground">{subtext}</div>
              )}
            </CardTitle>
            <div className="h-6 w-6 flex-shrink-0">
              {tooltipContent && (
                <Tooltip>
                  <TooltipTrigger asChild>{icon}</TooltipTrigger>
                  <TooltipContent side="right">{tooltipContent}</TooltipContent>
                </Tooltip>
              )}
              {!tooltipContent && icon}
            </div>
          </div>
        )}
      </CardHeader>
      <CardContent className="space-y-6">
        {isLoading ? (
          <Skeleton className="h-8 w-full max-w-[100px]" />
        ) : (
          <div className="flex flex-col">
            <div className="h-8 text-2xl font-medium">
              {formattedTotalAmount}
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default NumberCard;
