import React from "react";
import { useFieldArray, UseFormReturn } from "react-hook-form";
import { Contact } from "@/models/contact";
import { Input } from "@/components/ui/input";
import { MapPinPlus, TrashIcon } from "lucide-react";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { t } from "i18next";
import { FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import CountrySelect from "@/feature/contacts/form/optional/addr/CountrySelect";

const AddressFormFields = ({ form }: { form: UseFormReturn<Contact> }) => {
  const {
    control,
    register,
    formState: { errors },
  } = form;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "addresses",
  });
  return (
    <>
      {fields.map((field, index) => {
        const addressError = errors.addresses?.[index]?.root;
        return (
          <>
            <div
              className={cn(
                "flex flex-auto flex-col gap-2 rounded-md p-4",
                index % 2 === 0 ? "bg-muted/40" : "bg-muted/20",
                addressError ? "border border-destructive" : "",
              )}
              key={field.id + "address_wrapper"}
            >
              {addressError && (
                <p className="text-[0.8rem] font-medium text-destructive">
                  {addressError.message}
                </p>
              )}
              <div className="flex">
                <h4 className={"font-semibold text-muted-foreground"}>
                  Adresse {index + 1}
                </h4>
                <Button
                  variant={"ghost"}
                  size={"icon"}
                  type="button"
                  className={"ml-auto text-red-300"}
                  onClick={() => remove(index)}
                >
                  <TrashIcon></TrashIcon>
                </Button>
              </div>

              <div key={field.id} className="grid w-full grid-cols-4 gap-3">
                <FormItem>
                  <FormLabel>
                    {t("component.contacts.addresses.street")}
                  </FormLabel>
                  <Input
                    {...register(`addresses.${index}.street` as const)}
                    defaultValue={field.street}
                  />
                  <FormMessage />
                </FormItem>
                <FormItem>
                  <FormLabel>
                    {t("component.contacts.addresses.postalCode")}
                  </FormLabel>
                  <Input
                    {...register(`addresses.${index}.postalCode` as const)}
                    defaultValue={field.postalCode}
                  />
                  <FormMessage />
                </FormItem>
                <FormItem>
                  <FormLabel>
                    {t("component.contacts.addresses.city")}
                  </FormLabel>
                  <Input
                    {...register(`addresses.${index}.city` as const)}
                    defaultValue={field.city}
                  />
                  <FormMessage />
                </FormItem>

                <CountrySelect form={form} index={index} />
              </div>
            </div>
          </>
        );
      })}
      {fields.length < 10 && (
        <Button
          variant={"ghost"}
          type="button"
          className={"text-emerald-600"}
          onClick={() =>
            append({ street: "", postalCode: "", city: "", country: "" })
          }
        >
          <MapPinPlus className={"mr-2"}></MapPinPlus>
          {t("component.contacts.addresses.add")}
        </Button>
      )}
    </>
  );
};

export default AddressFormFields;
