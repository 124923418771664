import { Center } from "@/components/layout/Center";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import Logo from "@/components/page/components/Logo";
import CompleteForm from "@/feature/auth/components/CompleteForm";
import React from "react";
import { useNavigate } from "react-router-dom";

export const Invite = () => {
  const queryParams = new URLSearchParams(
    window.location.hash.replace("#", ""),
  );
  const inviteToken = queryParams.get("i");
  const navigation = useNavigate();

  if (!inviteToken) {
    navigation("/");
    return null;
  }
  return (
    <Center>
      <Card className="m-auto max-w-xl bg-card flex flex-col gap-8">
        <CardHeader className="text-center">
          <Logo></Logo>
        </CardHeader>
        <CardContent>
          <CompleteForm inviteToken={inviteToken} />
        </CardContent>
      </Card>
    </Center>
  );
};
