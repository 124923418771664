import React from "react";

import { Card, CardContent, CardHeader } from "@/components/ui/card";
import Logo from "@/components/page/components/Logo";
import { Center } from "@/components/layout/Center";
import { useTranslation } from "react-i18next";
import { ResetForm } from "@/feature/auth/components/ResetForm";

const ResetPassword = () => {
  const queryParams = new URLSearchParams(
    window.location.hash.replace("#", ""),
  );
  const resetToken = queryParams.get("r");
  const { t } = useTranslation();

  if (!resetToken) {
    return (
      <Center>
        <Card className="m-auto flex max-w-xl flex-col gap-8 bg-card">
          <CardHeader className="text-center">
            <Logo></Logo>
          </CardHeader>
          <CardContent>
            <p>{t("component.auth.reset.noTokenError")}</p>
          </CardContent>
        </Card>
      </Center>
    );
  }

  return (
    <Center h v>
      <Card className="m-auto flex max-w-xl flex-col gap-8 bg-card">
        <CardHeader className="text-center">
          <Logo></Logo>
        </CardHeader>
        <CardContent>
          <ResetForm resetToken={resetToken} />
        </CardContent>
      </Card>
    </Center>
  );
};

export default ResetPassword;
