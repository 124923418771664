import React from "react";
import { X } from "lucide-react";
import { Button } from "@/components/ui/button";

const CloseButton = ({ onClick }: { onClick: () => void }) => (
  <Button variant="ghost" size="icon" onClick={onClick} className={"group"}>
    <X className={"transition-transform ease-in-out group-hover:scale-125"} />
  </Button>
);

export default CloseButton;
