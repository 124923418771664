import React from "react";
import { BiDocument } from "@/models/document";
import { blobUrl } from "@/api/reduxApi";
import { VerticalFlex } from "@/components/layout/Flex";

const BigDocumentPreview = ({ document }: { document: BiDocument }) => {
  const url = document.uploadDocument?.blob
    ? blobUrl(document.uploadDocument?.blob)
    : "";

  return (
    <VerticalFlex className={"sticky top-16 mt-12 h-[88vh] max-h-[88vh] pt-6"}>
      {document.uploadDocument?.blob &&
        (document.uploadDocument.blob.mime === "application/pdf" ? (
          <iframe
            title={document.uploadDocumentId}
            className="h-full w-full"
            src={url}
          />
        ) : (
          ["image/png", "image/jpeg"].includes(
            document.uploadDocument.blob.mime,
          ) && (
            <div className={"ml-auto flex h-full"}>
              <img
                className="ml-auto flex h-full w-full object-contain"
                src={url}
                alt="Document preview"
              />
            </div>
          )
        ))}
    </VerticalFlex>
  );
};

export default BigDocumentPreview;
