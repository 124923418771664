import { toast } from "@/components/ui/use-toast";
import { GLOBAL_API_TOKEN } from "@/api/reduxApi";

export function copyApiTokenToClipboard() {
  if (GLOBAL_API_TOKEN) {
    navigator.clipboard
      .writeText(GLOBAL_API_TOKEN)
      .then(() => {
        toast({
          title: "API Token copied to clipboard!",
          description: "DEBUG",
        });
      })
      .catch((err) => console.error("Could not copy text: ", err));
  }
}
