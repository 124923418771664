import { useTranslation } from "react-i18next";
import { Badge } from "@/components/ui/badge";
import { BiDocumentKind } from "@/models/document";
import React, { FC } from "react";

export const InvoiceBadge: FC<{}> = () => {
  const { t } = useTranslation();

  return (
    <Badge className={"font-semibold text-teal-800"} variant="secondary">
      {t("model.document.kind." + BiDocumentKind.Invoice)}
    </Badge>
  );
};

export const ReceiptBadge: FC<{}> = () => {
  const { t } = useTranslation();

  return (
    <Badge className={"font-semibold text-orange-800"} variant="secondary">
      {t("model.document.kind." + BiDocumentKind.Receipt)}
    </Badge>
  );
};
