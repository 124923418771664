import React from "react";
import { BiDocument } from "@/models/document";
import { HorizontalFlex } from "@/components/layout/Flex";
import DocumentActions from "@/feature/documents/components/Card/CardHeader/DocumentActions";
import CloseButton from "@/feature/documents/components/Card/CardHeader/CloseButton";
import { ChevronDown, ChevronUp } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useDocumentContext } from "@/feature/documents/DocumentContextProvider";

const DocumentCardHeader = ({ document }: { document: BiDocument }) => {
  const {
    selectNextDocument,
    selectPreviousDocument,
    selectDocumentById,
    setMode,
    mode,
  } = useDocumentContext();

  return (
    <HorizontalFlex className={"items-center justify-between"}>
      <Button
        size={"icon"}
        variant="ghost"
        className={"group"}
        onClick={selectPreviousDocument}
      >
        <ChevronUp
          className={
            "text-muted-foreground transition-all active:scale-90 group-hover:mb-1 group-hover:text-foreground"
          }
        />
      </Button>
      <Button
        size={"icon"}
        variant="ghost"
        className={"group"}
        onClick={selectNextDocument}
      >
        <ChevronDown
          className={
            "text-muted-foreground transition-all active:scale-90 group-hover:mt-1 group-hover:text-foreground"
          }
        />
      </Button>
      <HorizontalFlex className={"flex-1 basis-2 items-center justify-end"}>
        <DocumentActions biDocument={document} />
        <CloseButton
          onClick={() => {
            if (mode === "small") {
              selectDocumentById(undefined);
            }
            setMode("small");
          }}
        />
      </HorizontalFlex>
    </HorizontalFlex>
  );
};

export default DocumentCardHeader;
