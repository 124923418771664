import React, { FC } from "react";
import { GoCardlessRequisitionStatus } from "@/models/goCardlessRequisition";
import { useTranslation } from "react-i18next";
import { VerticalFlex } from "@/components/layout/Flex";

export const GoCardlessStatus: FC<{
  requisitionStatus: GoCardlessRequisitionStatus;
}> = ({ requisitionStatus }) => {
  const { t } = useTranslation();

  return (
    <VerticalFlex className={"select-none items-center"}>
      {requisitionStatus === GoCardlessRequisitionStatus.LN && (
        <span
          className={
            "rounded-[6px] border border-green-500/50 px-1 py-0.5 text-xs text-green-500/50"
          }
        >
          {t("component.bankSettings.requisitionCard.status.active")}
        </span>
      )}
      {requisitionStatus === GoCardlessRequisitionStatus.EX && (
        <span
          className={
            "rounded-[6px] border border-red-500/50 px-1 py-0.5 text-xs text-red-500/50"
          }
        >
          {t("component.bankSettings.requisitionCard.status.expiration")}
        </span>
      )}
      {requisitionStatus !== GoCardlessRequisitionStatus.LN &&
        requisitionStatus !== GoCardlessRequisitionStatus.EX && (
          <span
            className={
              "rounded-[6px] border border-yellow-500/50 px-1 py-0.5 text-xs text-yellow-500/50"
            }
          >
            {t("component.bankSettings.requisitionCard.status.pending")}
          </span>
        )}
    </VerticalFlex>
  );
};
