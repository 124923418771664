import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  getDaysBetweenDates,
  getMonthsBetweenDates,
  listDaysBetweenDates,
  listMonthsBetweenDates,
  listWeeksBetweenDates,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from "@/util/date";
import { translation } from "@/i18n/translation";
import { getISOWeek } from "date-fns";

export type DateUnitType = "day" | "week" | "month";

export interface DateUnit {
  startOf: (date: Date) => Date;
  endOf: (date: Date) => Date;
  listBetweenDates: (from: Date, to: Date) => Date[];
  unitBetweenDates: (from: Date, to: Date) => number;
  formatString: (lang: string, date: Date) => string;
}

const Day: DateUnit = {
  startOf: startOfDay,
  endOf: endOfDay,
  listBetweenDates: listDaysBetweenDates,
  unitBetweenDates: getDaysBetweenDates,
  formatString: (lang: string, date: Date) => {
    return date.toLocaleDateString(lang, {
      dateStyle: "medium",
    });
  },
};

const Week: DateUnit = {
  startOf: startOfWeek,
  endOf: endOfWeek,
  listBetweenDates: listWeeksBetweenDates,
  unitBetweenDates: (a, b) => getDaysBetweenDates(a, b) / 7,
  formatString: (lang: string, date: Date) => {
    let { t } = translation;
    return t("common.isoWeekDateFormat", {
      week: getISOWeek(date),
      year: date.getFullYear(),
    });
  },
};

const Month: DateUnit = {
  startOf: startOfMonth,
  endOf: endOfMonth,
  listBetweenDates: listMonthsBetweenDates,
  unitBetweenDates: getMonthsBetweenDates,
  formatString: (lang: string, date: Date) => {
    return date.toLocaleDateString(lang, {
      month: "short",
      year: "numeric",
    });
  },
};

export const DateUnits = {
  day: Day,
  week: Week,
  month: Month,
};
