import { useEffect, useState } from "react";
import { FilterInner } from "@/api/types";
import MultipleSelector from "@/components/ui/multiple-selector";
import * as React from "react";
import { Path } from "@/util/DeepProps";

export interface SelectGroup<T> {
  key: keyof T & string;
  label: string;
  values: {
    label: string;
    value: string;
    filter?: FilterInner<T>;
  }[];
}

export interface SearchValues {
  search: string;
  searchTags: string[];
}

interface DataTableMultiselectProps<T> {
  selectGroups: SelectGroup<T>[];
  additionalFilters?: FilterInner<T>[];
  textSearchKeys?: Path<T>[];
  onFilterChange?: (filter: FilterInner<T>) => void;
  onChange?: (value: SearchValues) => void;
  placeholder: string;
  debounce?: number;
  value?: SearchValues;
}

export function DataTableMultiselect<T>(props: DataTableMultiselectProps<T>) {
  const [search, setSearch] = useState<string>("");
  const [searchTags, setSearchTags] = useState<string[]>([]);

  useEffect(() => {
    if (props.value) {
      setSearch(props.value.search);
      setSearchTags(props.value.searchTags);
      console.log(search);
      console.log(searchTags);
    }
  }, [JSON.stringify(props.value)]);

  useEffect(() => {
    props.onChange?.({
      search,
      searchTags,
    });
  }, [search, searchTags]);

  const updateFilter = () => {
    let filters: FilterInner<T>[] = [];

    // additional filters
    if (props.additionalFilters) {
      filters.push(...props.additionalFilters);
    }

    // search terms
    if (search && props.textSearchKeys && props.textSearchKeys.length > 0) {
      for (let word of search.split(/\s+/)) {
        if (!word) {
          continue;
        }

        filters.push({
          kind: "or",
          children: props.textSearchKeys.map((k) => ({
            kind: "in",
            attribute: k,
            value: word,
          })),
        });
      }
    }

    // selected filters
    for (let group of props.selectGroups) {
      let selectedValues = searchTags.flatMap((value) => {
        return group.values.filter((v) => `${group.key}:${v.value}` === value);
      });

      if (selectedValues.length > 0) {
        filters.push({
          kind: "or",
          children: selectedValues.map(
            (v) =>
              v.filter || {
                kind: "eq",
                attribute: group.key,
                value: v.value,
              },
          ),
        });
      }
    }

    if (filters.length > 0) {
      props.onFilterChange?.({
        kind: "and",
        children: filters,
      });
    } else {
      props.onFilterChange?.({});
    }
  };

  useEffect(() => {
    let timer = setTimeout(updateFilter, props.debounce || 400);
    return () => clearTimeout(timer);
  }, [search, searchTags, props.additionalFilters, props.debounce]);

  let availableTags = props.selectGroups.flatMap((group) =>
    group.values.map((e) => ({
      label: e.label,
      header: group.label,
      value: `${group.key}:${e.value}`,
    })),
  );

  return (
    <MultipleSelector
      placeholder={props.placeholder}
      className="flex-1"
      groupBy={"header"}
      showGroupInBadge
      value={availableTags.filter((a) => searchTags.includes(a.value))}
      options={availableTags}
      onChange={(op) => {
        setSearchTags(op.map((o) => o.value));
      }}
      inputProps={{
        onValueChange: setSearch,
      }}
    />
  );
}
