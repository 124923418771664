import React from "react";
import { BiDocument } from "@/models/document";

export type DocumentMode = "small" | "preview" | "edit" | "edit-saved";

function useDocumentNavigation(documents: BiDocument[]) {
  const [selectedDocumentId, setSelectedDocumentId] = React.useState<
    string | undefined
  >(undefined);
  const [mode, setMode] = React.useState<DocumentMode>("small");

  const selectDocumentById = (id: string | undefined) => {
    setSelectedDocumentId(id);
    if (id) {
      const element = document.getElementById(id);
      if (element) {
        const rect = element.getBoundingClientRect();
        const isVisible =
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <=
            (window.innerWidth || document.documentElement.clientWidth);

        if (!isVisible) {
          element.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
          });
        }
      }
    }
  };

  const selectNextDocument = () => {
    if (mode === "edit-saved") {
      setMode("edit");
    }

    if (documents.length === 0) return;

    const selectedIndex = selectedDocumentId
      ? documents.findIndex((doc) => doc.id === selectedDocumentId)
      : -1;
    const nextIndex = (selectedIndex + 1) % documents.length;

    selectDocumentById(documents[nextIndex].id);
  };

  const selectPreviousDocument = () => {
    if (mode === "edit-saved") {
      setMode("edit");
    }

    if (documents.length === 0) return;

    const selectedIndex = selectedDocumentId
      ? documents.findIndex((doc) => doc.id === selectedDocumentId)
      : documents.length;
    const prevIndex = (selectedIndex - 1 + documents.length) % documents.length;
    selectDocumentById(documents[prevIndex].id);
  };

  return {
    selectedDocumentId,
    selectDocumentById,
    selectNextDocument,
    selectPreviousDocument,
    mode,
    setMode,
  };
}

export default useDocumentNavigation;
