import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import { CardFooter } from "@/components/ui/card";
import { Center } from "@/components/layout/Center";
import { CompleteResetForm } from "@/feature/auth/components/CompleteResetForm";
import { useAcceptInviteMutation } from "@/api/endpoints/authApi";
import { useAuth } from "@/feature/auth/AuthProvider";
import { useNavigate } from "react-router-dom";
import FormErrorDisplay from "@/components/error/FormErrorDisplay";

const CompleteForm = ({
  resetToken,
  inviteToken,
}: {
  resetToken?: string;
  inviteToken: string;
}) => {
  const { t } = useTranslation();
  const [acceptInvite, acceptInviteResult] = useAcceptInviteMutation();
  const auth = useAuth();
  const navigation = useNavigate();

  if (resetToken) {
    return (
      <Center className={"flex-col gap-4"} h v>
        <CompleteResetForm resetToken={resetToken} inviteToken={inviteToken} />
        <span className="text-sm text-muted-foreground">
          {" "}
          <CardFooter>{t("component.auth.complete.rejectAction")}</CardFooter>
        </span>
      </Center>
    );
  }

  return (
    <Center className={"flex-col gap-4"} h v>
      <Button
        onClick={async () => {
          // console.log("inviteToken:", inviteToken);
          await acceptInvite({ token: inviteToken }).then((data) => {
            if ("data" in data) {
              auth.login(data.data.token, data.data.identity.company);
              navigation("/");
            }
          });
        }}
      >
        {t("component.auth.complete.acceptAction")}
      </Button>
      {"error" in acceptInviteResult && (
        <FormErrorDisplay error={acceptInviteResult.error} />
      )}
      <span className="text-sm text-muted-foreground">
        {" "}
        <CardFooter>{t("component.auth.complete.rejectAction")}</CardFooter>
      </span>
    </Center>
  );
};

export default CompleteForm;
