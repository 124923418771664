import React from "react";
import { FlexOne, HorizontalFlex } from "@/components/layout/Flex";
import { cn } from "@/lib/utils";
import { CoinsIcon } from "lucide-react";
import { formatMoney } from "@/util/format";
import { useGetBankTransactionQuery } from "@/api/endpoints/bankApi";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Skeleton } from "@/components/ui/skeleton";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";

const SmallTransactionPreview = ({
  transactionIds,
}: {
  transactionIds: string[];
}) => {
  return (
    <ScrollArea className={"max-h-24"}>
      {transactionIds.map((transactionID) => (
        <SmallTransactionPreviewElement transactionID={transactionID} />
      ))}
    </ScrollArea>
  );
};

export default SmallTransactionPreview;

interface SmallTransactionPreviewProps {
  transactionID: string;
}

const SmallTransactionPreviewElement = ({
  transactionID,
}: SmallTransactionPreviewProps) => {
  const { data, isLoading } = useGetBankTransactionQuery(
    { id: transactionID || "" },
    { skip: !transactionID },
  );

  const negativeValue =
    data?.transactionAmount !== undefined && data.transactionAmount < 0;
  return (
    <HorizontalFlex
      className={cn(
        "relative select-none items-center justify-between overflow-hidden rounded-[6px] border border-foreground/5 py-2 text-sm text-foreground hover:bg-foreground/15",
      )}
    >
      {isLoading ? (
        <Skeleton className={"h-12"} />
      ) : (
        <Tooltip>
          <TooltipTrigger asChild>
            <div className={"flex w-full flex-row gap-2"}>
              <CoinsIcon
                className={cn(
                  negativeValue ? "text-red-500" : "text-green-500",
                )}
              />
              <HorizontalFlex>
                <span className={"font-semibold"}>{data?.otherPartyName}</span>

                <span className={"w-56 truncate text-sm"}>
                  {data?.remittanceInformation}
                </span>
              </HorizontalFlex>
              <span className={"ml-auto font-semibold"}>
                {formatMoney(data?.transactionAmount)}
              </span>
            </div>
          </TooltipTrigger>
          <TooltipContent>
            <p>{data?.otherPartyName}</p>
            <span className={"text-xs text-muted-foreground"}>
              {data?.remittanceInformation}
            </span>
          </TooltipContent>
        </Tooltip>
      )}
    </HorizontalFlex>
  );
};
