import React, { useState, useEffect } from "react";
import {
  MailOpen,
  Clock1,
  Clock2,
  Clock3,
  Clock4,
  Clock5,
  Clock6,
  Clock7,
  Clock8,
  Clock9,
  Clock10,
  Clock11,
  Clock12,
} from "lucide-react";

const clockIcons = [
  Clock1,
  Clock2,
  Clock3,
  Clock4,
  Clock5,
  Clock6,
  Clock7,
  Clock8,
  Clock9,
  Clock10,
  Clock11,
  Clock12,
];

const Invoices = () => {
  const [currentClock, setCurrentClock] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentClock((prevClock) => (prevClock + 1) % clockIcons.length);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const ClockIcon = clockIcons[currentClock];

  return (
    <div className="flex flex-1 flex-col items-center justify-center">
      <MailOpen className="h-16 w-16 text-gray-500" />
      <h1 className="mt-4 text-4xl font-bold">Rechnungen</h1>
      <p className="mt-2 text-xl text-gray-500">Kommt bald</p>
      <ClockIcon className="mt-4 h-12 w-12 text-gray-400" />
    </div>
  );
};

export default Invoices;
