import React from 'react';
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { ArrowUpIcon, ArrowDownIcon } from '@radix-ui/react-icons';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import colors from '../../../../colors'; // Passe den Pfad an

const primaryColor = colors.foreground;

const formatCurrency = (value: number) => {
  return value.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' });
};

const formatDate = (date: Date) => {
  return date.toLocaleDateString('de-DE', { month: 'short', year: 'numeric' });
};

const calculateTrend = (data: { name: string, value: number }[]) => {
  const firstValue = data[0]?.value || 0;
  const lastValue = data[data.length - 1]?.value || 0;
  const percentageChange = ((lastValue - firstValue) / firstValue) * 100;
  const trend = lastValue >= firstValue ? 'up' : 'down';
  return { trend, percentageChange };
};

const AreaChartCard: React.FC<{ title: string, data: { name: string, value: number }[], dateRange: { from: Date, to: Date } }> = ({ title, data, dateRange }) => {
  const latestDataValue = data[data.length - 1]?.value || 0;
  const { trend, percentageChange } = calculateTrend(data);

  const trendText = dateRange
    ? `von ${formatDate(new Date(dateRange.from))} bis ${formatDate(new Date(dateRange.to))}`
    : 'im vgl. zum Vormonat';

  return (
    <Card>
      <CardHeader className="flex justify-between">
        <div className="flex flex-col mb-4">
          <CardTitle className="text-2xl font-semibold mt-2">
            {title || 'Platzhalter Titel'}
          </CardTitle>
          <div className="flex items-center mt-2">
            {trend === 'up' ? (
              <ArrowUpIcon className="h-5 w-5 text-green-500" />
            ) : (
              <ArrowDownIcon className="h-5 w-5 text-red-500" />
            )}
            <span className="text-sm font-medium ml-2">{`${percentageChange.toFixed(2)}% ${trendText}`}</span>
          </div>
        </div>
      </CardHeader>
      <CardContent className="pb-4">
        <div className="h-[300px]">
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 10,
                bottom: 0,
              }}
            >
              <defs>
                <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={primaryColor} stopOpacity={0.8} />
                  <stop offset="95%" stopColor={primaryColor} stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis dataKey="name" axisLine={false} tickLine={false} />
              <YAxis axisLine={false} tickLine={false} />
              <Tooltip
                content={({ active, payload }) => {
                  if (active && payload && payload.length) {
                    return (
                      <div className="rounded-lg border bg-background p-2 shadow-sm">
                        <div className="grid grid-cols-2 gap-2">
                          <div className="flex flex-col">
                            <span className="text-[0.70rem] uppercase text-muted-foreground">
                              {title}
                            </span>
                            <span className="font-bold text-muted-foreground">
                              {formatCurrency(payload[0]?.value as number || 0)}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  }
                  return null;
                }}
              />
              <Area
                type="monotone"
                dataKey="value"
                stroke={primaryColor}
                strokeWidth={3}
                strokeOpacity={1}
                fillOpacity={0.15}
                fill="url(#colorGradient)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </CardContent>
    </Card>
  );
};

export default AreaChartCard;
