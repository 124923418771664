import { useEffect, useRef, useState } from 'react';
import { Separator } from '@/components/ui/separator';
import { ChatMessage } from './ChatMessage';

export function ChatList({ messages }) {
  const endOfMessagesRef = useRef(null);
  const [isInitialRender, setIsInitialRender] = useState(true);

  useEffect(() => {
    if (isInitialRender) {
      endOfMessagesRef.current?.scrollIntoView();  // Hier erfolgt das sofortige Scrollen
      setIsInitialRender(false); // Das erste Rendering wurde abgeschlossen
    } else {
      endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });  // Ansonsten glattes Scrollen
    }
  }, [messages]);

  if (!messages.length) {
    return null;
  }

  return (
    <div className="relative mx-auto max-w-3xl px-4">
      {console.log("Rendering messages:", messages)}
      {messages.map((message, index) => (
        <div key={index}>
          <ChatMessage message={message} />
          {index < messages.length - 1 && (
            <Separator className="my-4 md:my-8" />
          )}
          {index === messages.length - 1 && (
            <div ref={endOfMessagesRef} />
          )}
        </div>
      ))}
    </div>
  );
}
