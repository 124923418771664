import React from "react";
import { Title } from "@/components/text/Title";
import { Text } from "@/components/text/Text";
import { HorizontalFlex } from "@/components/layout/Flex";
import { Center } from "@/components/layout/Center";
import { Line } from "@/components/layout/Line";
import { useTranslation } from "react-i18next";

const PageNotFound = () => {
  const { t } = useTranslation();
  return (
    <Center v h>
      <HorizontalFlex>
        <Title>404</Title>
        <Line v />
        <Text>{t("error.component.notFound")}</Text>
      </HorizontalFlex>
    </Center>
  );
};

export default PageNotFound;
