import React, { useState } from "react";
import { LoadingButton } from "@/components/ui/loadingButton";
import { Center } from "@/components/layout/Center";
import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import { useModal } from "@/components/modal/useModal";
import { Trash2 } from "lucide-react";
import { FlexOne, HorizontalFlex } from "@/components/layout/Flex";

export interface ConfirmDescription {
  text: React.ReactNode;
  onAbort?: () => void;
  onAction?: () => Promise<void> | undefined;
}

export const useConfirmModal = () => {
  const { showModal } = useModal();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  return {
    confirmDelete: (args: ConfirmDescription) => {
      showModal({
        content: ({ close }) => (
          <>
            {typeof args.text === "string" ? (
              <Center v h className="gap-2">
                <Trash2 />
                <p>{args.text}</p>
              </Center>
            ) : (
              args.text
            )}

            <HorizontalFlex>
              <FlexOne />
              <Button
                variant="ghost"
                onClick={() => {
                  close();
                  args.onAbort?.();
                }}
              >
                {t("component.users.dropdown.cancel")}
              </Button>
              <LoadingButton
                variant="destructive"
                loading={loading}
                onClick={() => {
                  if (args.onAction) {
                    let res = args.onAction();
                    // if result is promise, wait for it to resolve before closing
                    if (res) {
                      setLoading(true);
                      res.finally(() => {
                        setLoading(false);
                        close();
                      });
                    } else {
                      close();
                    }
                  }
                }}
              >
                {t("component.users.dropdown.delete")}
              </LoadingButton>
            </HorizontalFlex>
          </>
        ),
      });
    },
  };
};
