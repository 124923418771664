import React, { useMemo, useState } from "react";
import {
  Area,
  Bar,
  ComposedChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { formatMoney } from "@/util/format";
import {
  FinanceStatisticDataPoint,
  useFinanceQuery,
} from "@/api/endpoints/analyticsApi";
import { DateRangePicker } from "@/components/ui/date-range-picker";
import colors, { colorsMap } from "@/colors";
import { useTranslation } from "react-i18next";
import { simpleDateFrom, simpleDateToDate } from "@/models/document";
import {
  endOfDay,
  getMonthsBetweenDates,
  listDaysBetweenDates,
  startOfDay,
} from "@/util/date";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  DateUnits,
  DateUnitType,
} from "@/feature/dashboard/components/charts/util/date";

const primaryColor = colors.muted.foreground;

type Selection = "balance" | "income" | "expenses" | "all";

const AreaChartCard: React.FC<{
  range: { from: Date; to: Date; unit: DateUnitType };
  onDateRangeChange: (range: {
    from: Date;
    to: Date;
    unit: DateUnitType;
  }) => void;
}> = ({ onDateRangeChange, range }) => {
  const { t, i18n } = useTranslation();
  const today = useMemo(() => endOfDay(new Date()), []);
  const id = useMemo(() => Math.random(), []);

  const [selectedMetric, setSelectedMetric] = useState<Selection>("all");

  const dayRanges = useMemo(() => {
    return listDaysBetweenDates(
      DateUnits[range.unit].startOf(range.from),
      DateUnits[range.unit].endOf(range.to),
    ).map((d) => ({
      from: simpleDateFrom(d),
      to: simpleDateFrom(d),
    }));
  }, [range]);

  const selectedRanges = useMemo(() => {
    return DateUnits[range.unit]
      .listBetweenDates(range.from, range.to)
      .map((d) => ({
        from: simpleDateFrom(DateUnits[range.unit].startOf(d)),
        to: simpleDateFrom(DateUnits[range.unit].endOf(d)),
      }));
  }, [range]);

  const { data: dataDays } = useFinanceQuery({
    ranges: dayRanges,
  });

  const { data } = useFinanceQuery({
    ranges: selectedRanges,
  });

  const mapData = (data: FinanceStatisticDataPoint[] | undefined) => {
    return (
      data?.map((data) => {
        let fromTime = startOfDay(simpleDateToDate(data.from)).getTime();
        let toTime = endOfDay(simpleDateToDate(data.to)).getTime();
        let deltaTime = toTime - fromTime;
        return {
          fromTime,
          toTime,
          deltaTime,
          time: Math.round(fromTime / 2 + toTime / 2),
          ...data,
        };
      }) ?? []
    );
  };

  const mappedDataDays = mapData(dataDays?.data);
  const mappedDataSelectedUnit = mapData(data?.data);

  let domain: [number, number] = [
    Math.min(...mappedDataSelectedUnit.map((d) => d.fromTime)),
    Math.max(...mappedDataSelectedUnit.map((d) => d.toTime)),
  ];

  return (
    <Card>
      <CardHeader className="flex flex-row justify-between gap-4">
        <div className={"w-48"}>
          <Select
            onValueChange={setSelectedMetric as any}
            value={selectedMetric}
          >
            <SelectTrigger className="bg-background">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectItem key="all" value="all">
                  {t("common.all")}
                </SelectItem>
                <SelectItem value="balance">
                  {t("component.analytics.balance")}
                </SelectItem>
                <SelectItem value="income">
                  {t("component.analytics.income")}
                </SelectItem>
                <SelectItem value="expenses">
                  {t("component.analytics.expenses")}
                </SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        <DateRangePicker
          preset={"month"}
          onUpdate={({ range }) => {
            if (!range) return;
            const from = startOfDay(range.from);
            const to = endOfDay(range.to ?? today);
            let unit: DateUnitType = "day";
            let monthBetween = getMonthsBetweenDates(from, to);
            if (monthBetween > 5) {
              unit = "month";
            } else if (monthBetween > 1) {
              unit = "week";
            }
            onDateRangeChange({ from, to, unit });
          }}
          initialDateFrom={range.from}
          initialDateTo={range.to}
          showCompare={false}
          initialSelected
        />
      </CardHeader>
      <CardContent className="pb-4">
        <div className="h-[420px]">
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              barGap={4}
              data={mappedDataSelectedUnit}
              margin={{
                top: 5,
                right: 30,
                left: 30,
                bottom: 0,
              }}
            >
              <defs>
                {["balance", "income", "expenses"].map((key) => (
                  <linearGradient
                    id={`${id}-colorGradient-${key}`}
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                    key={key}
                  >
                    <stop
                      offset="5%"
                      stopColor={colorsMap[key]}
                      stopOpacity={0.9}
                    />
                    <stop
                      offset="95%"
                      stopColor={colorsMap[key]}
                      stopOpacity={0}
                    />
                  </linearGradient>
                ))}
              </defs>
              <XAxis
                type={"number"}
                dataKey="time"
                axisLine={false}
                tickLine={false}
                domain={domain}
                scale={"time"}
                tickFormatter={(v) =>
                  DateUnits[range.unit].formatString(i18n.language, new Date(v))
                }
              />
              <XAxis
                type={"number"}
                dataKey="time"
                axisLine={false}
                tickLine={false}
                domain={domain}
                scale={"time"}
                hide={true}
                xAxisId={"balance"}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                tickFormatter={(value) => String(value / 100)}
              />

              {["income", "expenses"]
                .filter(
                  (key) => selectedMetric === "all" || key === selectedMetric,
                )
                .map((key) => (
                  <Bar
                    key={key}
                    dataKey={key}
                    type="monotone"
                    stroke={colorsMap[key]}
                    strokeWidth={2}
                    strokeOpacity={1}
                    fillOpacity={0.4}
                    fill={`url(#${id}-colorGradient-${key})`}
                  />
                ))}
              {["balance"]
                .filter(
                  (key) => selectedMetric === "all" || key === selectedMetric,
                )
                .map((key) => (
                  <Area
                    activeDot={false}
                    key={key}
                    data={mappedDataDays}
                    dataKey={key}
                    type="monotone"
                    stroke={primaryColor}
                    strokeWidth={2}
                    strokeOpacity={1}
                    fillOpacity={0.4}
                    xAxisId={"balance"}
                    fill={`url(#${id}-colorGradient-${key})`}
                  />
                ))}
              {mappedDataSelectedUnit.map((key, index) => (
                <ReferenceLine
                  key={"refline-" + index}
                  x={key.fromTime}
                  stroke="white"
                  strokeOpacity={0.3}
                />
              ))}
              <Tooltip
                content={({ active, payload, label }) => {
                  // this is a work around for a selection bug in recharts
                  let selected = mappedDataSelectedUnit.find(
                    (k) => k.time === label,
                  );
                  if (active && payload && payload.length && selected) {
                    return (
                      <div className="min-w-60 rounded-lg border bg-background p-2 shadow-sm">
                        <div
                          className={
                            "mb-2 border-b-2 border-muted-foreground pb-2 text-center text-xs font-bold opacity-50"
                          }
                        >
                          {DateUnits[range.unit]
                            .startOf(new Date(label))
                            .toLocaleDateString(i18n.language, {
                              dateStyle: "long",
                            })}
                          {" - "}
                          {DateUnits[range.unit]
                            .endOf(new Date(label))
                            .toLocaleDateString(i18n.language, {
                              dateStyle: "long",
                            })}
                        </div>
                        <div className="grid grid-cols-2 gap-2">
                          {payload.map(({ name }, index) => (
                            <div
                              className="flex flex-col"
                              key={`tooltip-item-${index}`}
                            >
                              <span className="text-[0.70rem] uppercase text-muted-foreground">
                                {t("component.analytics." + name)}
                              </span>
                              <span className="font-bold text-muted-foreground">
                                {formatMoney(
                                  (selected as any)[name as string] as number,
                                )}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    );
                  }
                  return null;
                }}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </CardContent>
    </Card>
  );
};

export default AreaChartCard;
