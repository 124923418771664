import React from "react";
import i18n from "i18next";
import { Center } from "@/components/layout/Center";
import { HorizontalFlex } from "@/components/layout/Flex";
import { Title } from "@/components/text/Title";
import { Line } from "@/components/layout/Line";
import { Text } from "@/components/text/Text";
import { Frown } from "lucide-react";

export interface ErrorBoundaryProps {
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: any;
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true, error: error };
  }

  componentDidUpdate(prevProps: ErrorBoundaryProps) {
    if (this.props.children !== prevProps.children) {
      this.setState({ hasError: false, error: null });
    }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    const { t } = i18n;
    if (this.state.hasError) {
      return (
        <Center v h>
          <HorizontalFlex>
            <Title>
              <Frown />
            </Title>
            <Line v />
            <Text>{t("error.component.loadingFailed")}</Text>
          </HorizontalFlex>
        </Center>
      );
    } else {
      return this.props.children;
    }
  }
}
