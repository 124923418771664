import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { User } from "@/models/user";
import { Company } from "@/models/company";
import { UserCompany } from "@/models/userCompany";
import { reduxApi, setGlobalApiToken } from "@/api/reduxApi";
import { store } from "@/api/store";
import { useGetUserQuery } from "@/api/endpoints/userApi";

const TOKEN_KEY = "bimetrics-token";
const COMPANY_KEY = "bimetrics-company";

const AuthContext = createContext<AuthContextType>({
  login: () => {
    throw new Error("Not Implemented");
  },
  logout: () => {
    throw new Error("Not Implemented");
  },
  switchCompany: () => {
    throw new Error("Not Implemented");
  },
  isLoading: false,
  userData: undefined,
});

export interface AuthenticatedUser {
  user: User;
  activeCompany: Company;
  activeIdentity: UserCompany;
  identities: UserCompany[];
}

export interface AuthContextType {
  userData?: AuthenticatedUser;
  login: (token: string, activeCompany: Company) => void;
  logout: () => void;
  switchCompany?: (activeCompany: Company) => void;
  isLoading: boolean;
}

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [loginToken, setLoginToken] = useState<{
    token: string;
    activeCompany: string;
  } | null>(() => {
    let token = localStorage.getItem(TOKEN_KEY);
    let activeCompany = localStorage.getItem(COMPANY_KEY);
    setGlobalApiToken(token + ":" + activeCompany);
    if (token && activeCompany) {
      return {
        token,
        activeCompany,
      };
    } else {
      return null;
    }
  });

  const { data, isLoading } = useGetUserQuery(undefined, {
    skip: !loginToken,
  });

  useEffect(() => {
    if (loginToken) {
      localStorage.setItem(TOKEN_KEY, loginToken.token);
      localStorage.setItem(COMPANY_KEY, loginToken.activeCompany);
    } else {
      localStorage.removeItem(TOKEN_KEY);
      localStorage.removeItem(COMPANY_KEY);
    }
  }, [loginToken]);

  const login = (token: string, activeCompany: Company) => {
    // update immediately
    setGlobalApiToken(token + ":" + activeCompany.id);
    store.dispatch(reduxApi.util.resetApiState());
    setLoginToken({
      token,
      activeCompany: activeCompany.id,
    });
  };

  const logout = () => {
    setLoginToken(null);
  };

  let switchCompany =
    loginToken !== null
      ? (activeCompany: Company) => {
          // update immediately
          setGlobalApiToken(loginToken.token + ":" + activeCompany.id);
          store.dispatch(reduxApi.util.resetApiState());
          setLoginToken({
            token: loginToken.token,
            activeCompany: activeCompany.id,
          });
        }
      : undefined;

  let userData: AuthenticatedUser | undefined = undefined;
  if (data && loginToken) {
    userData = {
      user: data.user,
      activeCompany:
        data.identities.find((c) => c.company.id === loginToken.activeCompany)
          ?.company || data.identities[0].company,
      identities: data.identities,
      activeIdentity:
        data.identities.find(
          (c) => c.company.id === loginToken.activeCompany,
        ) || data.identities[0],
    };
  }

  return (
    <AuthContext.Provider
      value={{ login, logout, switchCompany, userData, isLoading }}
    >
      {children}
    </AuthContext.Provider>
  );
};
