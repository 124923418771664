import { FC } from "react";
import { Badge } from "@/components/ui/badge";
import { useTranslation } from "react-i18next";
import { BiDocument, BiDocumentKind } from "@/models/document";
import { BankTransaction } from "@/models/bankTransaction";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";

export const BookingBadge: FC<{
  item: BiDocument | BankTransaction;
}> = ({ item }) => {
  const { t } = useTranslation();

  function isInvalid(item: BiDocument | BankTransaction) {
    //if item is an BankTransaction
    if ("status" in item) {
      return false;
    } else {
      let docItem = item as BiDocument;
      return (
        docItem.invoiceNumber === null ||
        docItem.invoiceDate === null ||
        (docItem.kind === BiDocumentKind.Invoice &&
          (docItem.recipient.name === "" ||
            docItem.recipient.address === "")) ||
        (docItem.kind === BiDocumentKind.Receipt &&
          (docItem.issuer.name === "" || docItem.issuer.address === "")) ||
        docItem.totalTaxAmount === null ||
        docItem.totalAmount === null ||
        docItem.totalAmount === 0 ||
        docItem.category === ""
      );
    }
  }

  const isAutomatic =
    item.bookingTransactions &&
    item.bookingTransactions[0]?.source !== "manual";

  if (item) {
    return (
      <div className="relative inline-block">
        {isInvalid(item) ? (
          <Badge className={"bg-yellow-400/80 text-center"}>
            {t("model.document.status_invalid")}
          </Badge>
        ) : !item.bookingTransactions ||
          item.bookingTransactions.length === 0 ? (
          <Badge className={"bg-gray-400 text-center"}>
            {t("model.document.status_open")}
          </Badge>
        ) : item.bookingTransactions[0].amountOpen === 0 ? (
          <Badge className={"self-start bg-green-400"}>
            {t("model.document.status_booked")}
          </Badge>
        ) : (
          <Badge className={"self-start bg-orange-400"}>
            {t("model.document.status_partial")}
          </Badge>
        )}
        {isAutomatic && item.bookingTransactions?.length !== 0 && (
          <Tooltip>
            <TooltipTrigger asChild>
              <span className="absolute right-[-4px] top-[-4px] block h-2 w-2 rounded-full bg-muted-foreground shadow-sm shadow-accent"></span>
            </TooltipTrigger>
            <TooltipContent>
              <p>Das wurde automatisch gematcht. Bitte überprüfen.</p>
            </TooltipContent>
          </Tooltip>
        )}
      </div>
    );
  } else {
    return (
      <Badge className={"self-start bg-orange-400"}>
        {t("model.document.status_partial")}
      </Badge>
    );
  }
};
