import { useEffect, useState } from "react";
import NumberCard from "../charts/NumberCard";
import BarChartCard from "../charts/BarChartCard";
import AreaChartCard from "../charts/AreaChartCard";

export function Chart({ content }) {
    const [adaptedData, setAdaptedData] = useState([]);

    useEffect(() => {
        if (content?.queryType === "getPeriodicRevenueBreakdown" && content?.breakdown) {
            const adapted = content.breakdown.map(item => ({
                name: item.date,
                value: item.total
            }));
            setAdaptedData(adapted);
        }
    }, [content]);

    return (
        <>
            {content?.queryType === "getTotalRevenueForDateRange" && (
                <NumberCard
                    title={content?.title}
                    totalAmount={content?.incomeResult}
                    subtext={content?.startDate}
                />
            )}
            {content?.queryType === "getPeriodicRevenueBreakdown" && (
                <>
                    {/* <BarChartCard
                        title={content?.title}
                        // description={"TBD"}
                        data={adaptedData}
                        height={200}
                    /> */}
                    <AreaChartCard
                        title={content?.title}
                        data={adaptedData}
                        dateRange={{
                            from: new Date(content?.startDate),
                            to: new Date(content?.endDate)
                        }}
                    />
                </>
            )}
        </>
    );
}
