import { reduxApi } from "@/api/reduxApi";
import { BookingTransaction } from "@/models/BookingTransaction";
import { DatevValidation } from "@/models/datev";

const bookingApi = reduxApi.injectEndpoints({
  endpoints: (builder) => ({
    createBookingTransaction: builder.mutation<
      BookingTransaction,
      { transactionIds: string[]; documentIds: string[] }
    >({
      query: (body) => ({
        url: "booking/match",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Bank", "Document"],
    }),

    //delete booking transaction
    deleteBookingTransaction: builder.mutation<string, { id: string }>({
      query: (arg) => ({
        url: "booking/" + arg.id,
        method: "DELETE",
      }),
      invalidatesTags: ["Bank", "Document"],
    }),

    // check datev export
    getCheckDatevValidation: builder.query<
      DatevValidation,
      { from: string; to: string }
    >({
      query: (arg) =>
        "datev/check?from=" +
        encodeURIComponent(arg.from ?? "") +
        "&to=" +
        encodeURIComponent(arg.to ?? ""),
      providesTags: ["Document"],
    }),
  }),
});

export const {
  useCreateBookingTransactionMutation,
  useDeleteBookingTransactionMutation,
  useLazyGetCheckDatevValidationQuery,
} = bookingApi;
