import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";

import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import { useForgotMutation } from "@/api/endpoints/authApi";
import { toast } from "@/components/ui/use-toast";
import FormErrorDisplay from "@/components/error/FormErrorDisplay";
import React from "react";

const forgotFormSchema = z.object({ mail: z.string().email() });

type ForgotFormValues = z.infer<typeof forgotFormSchema>;

export function ForgotForm() {
  const { t } = useTranslation();
  const [forgot, forgotResult] = useForgotMutation();
  const defaultValues: Partial<ForgotFormValues> = { mail: "" };

  const form = useForm<ForgotFormValues>({
    resolver: zodResolver(forgotFormSchema),
    defaultValues,
    mode: "onBlur",
  });

  const onSubmit = async (data: ForgotFormValues) => {
    await forgot(data).then((data) => {
      if ("data" in data) {
        toast({
          title: t("component.auth.reset.resetSuccessTitle"),
          description: t("component.auth.reset.resetSuccessDescription"),
        });
      }
    });
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex w-96 flex-col space-y-2"
      >
        {/* MAIL */}
        <FormField
          control={form.control}
          name="mail"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("model.user.mail")}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="flex flex-col justify-center gap-4 pt-8">
          <Button
            type="submit"
            className="mx-auto w-full max-w-xs"
            disabled={forgotResult.isSuccess || forgotResult.isLoading}
          >
            {t("component.auth.reset.resetAction")}
          </Button>
          {forgotResult.isSuccess && (
            <span className={"text-sm"}>
              {t("component.auth.reset.resetSuccess")}
            </span>
          )}
          {"error" in forgotResult && (
            <FormErrorDisplay error={forgotResult.error} />
          )}
        </div>
      </form>
    </Form>
  );
}
