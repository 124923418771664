import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";

import { cn } from "@/lib/utils";
import { MemoizedReactMarkdown } from "./Markdown";
import { UserIcon, CircleIcon } from "lucide-react";
import { ChatMessageActions } from "./ChatMessageActions";
import { Chart } from "./Chart";

export function ChatMessage({ message, ...props }) {
  let parsedContent;
  console.log("updated? user message...", message);

  try {
    parsedContent = JSON.parse(message.content)
  } catch (error) {
    parsedContent = message.content;
  }

  return (
    <div
      className={cn(
        "group relative mb-4 flex items-start md:-ml-12 transition-transform transition-opacity duration-500 ease-out",
        "animate-slideIn"
      )}
      {...props}
    >
      <div
        className={cn(
          "flex h-8 w-8 shrink-0 select-none items-center justify-center rounded-md border shadow",
          message.role === "user" ? "bg-background" : "bg-primary text-primary-foreground"
        )}
      >
        {message.role === "user" ? <UserIcon /> : <CircleIcon />}
      </div>

      <div className="flex-1 px-1 ml-4 space-y-2 overflow-hidden">
        <MemoizedReactMarkdown
          className="prose break-words dark:prose-invert prose-p:leading-relaxed prose-pre:p-0 mt-0"
          remarkPlugins={[remarkGfm, remarkMath]}
          components={{
            p({ children }) {
              return <p className="mb-2 last:mb-0">{children}</p>;
            },
            code({ node, inline, className, children, ...props }) {
              if (children.length) {
                if (typeof children[0] === 'string' && children[0] === "▍") {
                  return (
                    <span className="mt-1 cursor-default animate-pulse">▍</span>
                  );
                }

                if (typeof children[0] === 'string') {
                  const firstChild = children[0].replace("`▍`", "▍");

                  if (inline) {
                    return (
                      <code className={className} {...props}>
                        {[firstChild, ...children.slice(1)]}
                      </code>
                    );
                  }
                }
              }
            },
          }}
        >
          {message.role === "user" ? message.content : parsedContent[0]}
        </MemoizedReactMarkdown>

        {parsedContent &&
          // parsedContent[1] &&
          parsedContent[1][0] &&
          message.role === "assistant" && (
            <div className="pt-5">
              <Chart content={parsedContent[1][0]} />
            </div>
          )}

        <ChatMessageActions message={message} />
      </div>
    </div>
  );
}
