import React, { createContext, useState } from "react";
import { GLOBAL_API_TOKEN, reduxApi } from "@/api/reduxApi";
import { useToast } from "@/components/ui/use-toast";
import { useTranslation } from "react-i18next";
import { Ban, Check, CircleX, HourglassIcon } from "lucide-react";
import { store } from "@/api/store";

type UploadStatus =
  | "loadstart"
  | "progress"
  | "abort"
  | "error"
  | "load"
  | "timeout"
  | "loadend";

export interface FileUploadProgress {
  id: string;
  file: File;
  progress: number;
  status: UploadStatus;
}

type UploadContextType = {
  uploads: FileUploadProgress[];
  addUpload: (XHR: UploadRequest) => void;
  updateUploadProgress: (
    id: string,
    file: File,
    progress: number,
    status: UploadStatus,
  ) => void;
};

type UploadRequest = {
  method: string;
  target: string;
  data: FormData;
  type: "invoice" | "receipt";
};

export const UploadContext = createContext<UploadContextType>({
  uploads: [],
  addUpload: () => {},
  updateUploadProgress: () => {},
});

export const UploadContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { toast } = useToast();
  const { t } = useTranslation();
  const [uploads, setUploads] = useState<FileUploadProgress[]>([]);
  const timeouts = new Map<string, NodeJS.Timeout>();

  const handleFileUpload = (files: File[], request: UploadRequest) => {
    const id = Math.random() + "";
    const xhr = new XMLHttpRequest();

    xhr.upload.addEventListener("loadstart", () => {
      files.forEach((file) => {
        setUploads((prevUploads) => [
          ...prevUploads,
          { id, file, progress: 0, status: "loadstart" },
        ]);
      });
    });

    xhr.upload.addEventListener("progress", (event) => {
      const progress = Math.round((event.loaded / event.total) * 100);
      files.forEach((file) =>
        updateUploadProgress(id, file, progress, "progress"),
      );
    });

    xhr.addEventListener("abort", () => {
      files.forEach((file) => updateUploadProgress(id, file, 0, "abort"));

      toast({
        icon: CircleX,
        title: t("common.abort"),
        description: t("component.documents.upload.abort"),
      });
    });

    xhr.addEventListener("error", () => {
      files.forEach((file) => updateUploadProgress(id, file, 0, "error"));

      toast({
        icon: Ban,
        title: t("common.error"),
        description: t("component.documents.upload.error"),
      });
    });

    xhr.addEventListener("timeout", () => {
      files.forEach((file) => updateUploadProgress(id, file, 0, "timeout"));

      toast({
        icon: HourglassIcon,
        title: t("common.timeout"),
        description: t("component.documents.upload.timeout"),
      });
    });

    xhr.addEventListener("load", () => {
      store.dispatch(reduxApi.util.invalidateTags(["Document"]));

      // console.log(files);
      if (xhr.status == 200) {
        files.forEach((file) => updateUploadProgress(id, file, 100, "load"));
        toast({
          icon: Check,
          title: t("common.success"),
          description: t("component.documents.upload.success", {
            count: files.length,
          }),
        });
      } else {
        files.forEach((file) => updateUploadProgress(id, file, 100, "error"));
        toast({
          icon: Check,
          title: t("common.error"),
          description: t("component.documents.upload.error", {
            count: files.length,
          }),
        });
      }
    });

    xhr.open(
      request.method,
      process.env.REACT_APP_API_URL + request.target,
      true,
    );
    xhr.setRequestHeader("Authorization", `Bearer ${GLOBAL_API_TOKEN}`);
    xhr.send(request.data);
  };

  const addUpload = (request: UploadRequest) => {
    const files = request.data.getAll(request.type + "[]") as File[];
    if (files.length === 0) {
      toast({
        icon: Ban,
        title: t("common.error"),
        description: t("component.documents.upload.error"),
      });
      return;
    }

    handleFileUpload(files, request);
  };

  const updateUploadProgress = (
    id: string,
    file: File,
    progress: number,
    status: UploadStatus,
  ) => {
    setUploads((prevUploads) => {
      const updatedUploads = prevUploads.map((upload) =>
        upload.id === id && upload.file === file
          ? { ...upload, progress, status }
          : upload,
      );

      if (status === "load" || status === "error") {
        const timeout = setTimeout(() => {
          setUploads((currentUploads) =>
            currentUploads.filter(
              (upload) => !(upload.id === id && upload.file === file),
            ),
          );
          timeouts.delete(id + file.name);
        }, Math.random() * 10000);

        timeouts.set(id + file.name, timeout);
      } else if (timeouts.has(id + file.name)) {
        clearTimeout(timeouts.get(id + file.name)!);
        timeouts.delete(id + file.name);
      }

      return updatedUploads;
    });
  };
  return (
    <UploadContext.Provider
      value={{ uploads, addUpload, updateUploadProgress }}
    >
      {children}
    </UploadContext.Provider>
  );
};
