import { useEffect, useState } from "react";
import { z } from "zod";
import { address } from "@/models/contact";

// Type inferred from addressSchema
type Address = z.infer<typeof address>;

const addressRegex =
  /^(?<street>.*?)(?:,\s*|\s+)(?<postalCode>\d{4,5})(?:\s*)(?<city>[^,]+)(?:,\s*|\s+)?(?<country>[A-Za-z\s]*)?$/;

const useParseAddress = (addressString: string): Address | undefined => {
  const [parsedAddress, setParsedAddress] = useState<Address | undefined>(
    undefined,
  );

  useEffect(() => {
    if (!addressString) {
      return;
    }
    const parseAddress = (address: string): Address | undefined => {
      const match = address.match(addressRegex);
      if (match && match.groups) {
        return {
          street: match.groups.street?.trim(),
          postalCode: match.groups.postalCode?.trim(),
          city: match.groups.city?.trim(),
          country: match.groups.country?.trim(),
        };
      }
      return {};
    };

    const addressObj = parseAddress(addressString);
    setParsedAddress(address.parse(addressObj));
  }, [addressString]);

  return parsedAddress;
};

export default useParseAddress;

export const addressToString = (address: Address | undefined): string => {
  if (!address) {
    return "";
  }
  const { street, postalCode, city, country } = address;
  let addressString = `${street}, ${postalCode} ${city}`;

  // Only add the country if it exists
  if (country && country.trim() !== "") {
    addressString += `, ${country}`;
  }

  return addressString;
};
