import React from "react";
import { useTranslation } from "react-i18next";
import { CardStackIcon } from "@radix-ui/react-icons";
import NumberCard, {
  NumberCardProps,
} from "@/feature/dashboard/components/charts/number/NumberCard";
import { SimpleDate, simpleDateToDate } from "@/models/document";
import i18n from "i18next";
import { DateUnitType } from "@/feature/dashboard/components/charts/util/date";

export const ExpensesNumberCard: React.FC<
  {
    unit: DateUnitType;
    range?: { from: SimpleDate; to: SimpleDate };
  } & Partial<NumberCardProps>
> = ({ unit, range, ...props }) => {
  const { t } = useTranslation();

  return (
    <NumberCard
      title={t("component.analytics.expenses_" + unit)}
      icon={<CardStackIcon className="h-5 w-5 text-muted-foreground" />}
      subtext={
        range &&
        t("common.dateRange", {
          from: simpleDateToDate(range.from).toLocaleDateString(i18n.language, {
            day: "numeric",
            month: "long",
          }),
          to: simpleDateToDate(range.to).toLocaleDateString(i18n.language, {
            day: "numeric",
            month: "long",
            year: "numeric",
          }),
        })
      }
      {...props}
    />
  );
};
