import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { FormSimpleText } from "@/components/form/FormSimpleText";
import { PhoneInput } from "@/components/ui/phone-input";
import FormErrorDisplay from "@/components/error/FormErrorDisplay";
import { Button } from "@/components/ui/button";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useTranslation } from "react-i18next";
import { useCreateCompanyMutation } from "@/api/endpoints/authApi";
import { Check } from "lucide-react";
import { toast } from "@/components/ui/use-toast";

interface AddCompanyFormProps {
  close: () => void;
}

const AddCompanyForm = ({ close }: AddCompanyFormProps) => {
  const { t } = useTranslation();
  const [createCompanyMutation, createcompanyResult] =
    useCreateCompanyMutation();

  const createCompanyFormSchema = z.object({
    companyName: z.string().min(4),
    phone: z.string(),
  });

  type CreateCompanyFormValues = z.infer<typeof createCompanyFormSchema>;

  const defaultValues: Partial<CreateCompanyFormValues> = {
    companyName: "",
    phone: "",
  };

  const form = useForm<CreateCompanyFormValues>({
    resolver: zodResolver(createCompanyFormSchema),
    defaultValues,
    mode: "onBlur",
  });

  const onSubmit = async (data: CreateCompanyFormValues) => {
    await createCompanyMutation(data).then((res) => {
      if ("data" in res) {
        toast({
          icon: Check,
          title: t("common.success"),
          description: t("component.myAccount.company.modal.createSuccess"),
        });
      }
      close();
    });
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col space-y-2"
      >
        {/* COMPANY */}
        <FormSimpleText
          form={form}
          name={"companyName"}
          label={t("model.company.name")}
        />

        {/* PHONE */}
        <FormField
          control={form.control}
          name="phone"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("model.company.phone")}</FormLabel>
              <FormControl>
                <PhoneInput {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormErrorDisplay error={createcompanyResult.error} />

        <div className="flex justify-center pt-8">
          <Button type="submit">
            {t("component.companySettings.addCompanyAction")}
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default AddCompanyForm;
