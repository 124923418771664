import React from "react";
import { ShieldAlert } from "lucide-react";
import { formatError } from "@/components/error/format";

const FormErrorDisplay = ({ error }: any) => {
  if (!error) return null;

  return (
    <span className="flex text-red-500 text-sm gap-1 ">
      <ShieldAlert size={16} className="inline" />
      {formatError(error)}{" "}
    </span>
  );
};

export default FormErrorDisplay;
