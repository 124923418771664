import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import { FileDown } from "lucide-react";
import { HorizontalFlex } from "@/components/layout/Flex";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { cn } from "@/lib/utils";
import React from "react";
import { useModal } from "@/components/modal/useModal";
import { ExportDatevForm } from "@/feature/documents/components/ExportDatevForm";
import { DateRange } from "react-day-picker";

interface DatevExportDropDownProps {
  disabled?: boolean;
  onValueChange?: (dateRange: DateRange, status: string) => void;
}

export const DatevExportDropDown = ({
  disabled = false,
  onValueChange,
}: DatevExportDropDownProps) => {
  const { t } = useTranslation();

  const { showModal } = useModal();

  const handleValueChange = (dateRange: DateRange, status: string) => {
    if (onValueChange) {
      onValueChange(dateRange, status);
    }
  };

  const showExportDatevModal = () => {
    showModal({
      title: t("component.documents.modal.title"),
      description: t("component.documents.modal.description"),
      content: ({ close }) => (
        <ExportDatevForm onValueChange={handleValueChange} close={close} />
      ),
      className: "overflow-hidden",
    });
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            disabled={disabled}
            variant="ghost"
            className={cn("bg-popover text-popover-foreground")}
          >
            <HorizontalFlex gap={2}>
              {t("common.export")}
              <FileDown size={18}></FileDown>
            </HorizontalFlex>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="flex flex-1 flex-col border-0">
          <DropdownMenuItem>
            <Button variant={"ghost"} onClick={showExportDatevModal}>
              <HorizontalFlex align="center" gap={2}>
                {t("component.documents.datev.export.documents")}
              </HorizontalFlex>
            </Button>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
};
