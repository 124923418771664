import React from "react";
import { useTranslation } from "react-i18next";
import { CardStackIcon } from "@radix-ui/react-icons";
import NumberCard, {
  NumberCardProps,
} from "@/feature/dashboard/components/charts/number/NumberCard";
import i18n from "i18next";
import { SimpleDate, simpleDateToDate } from "@/models/document";

export const CashNumberCard: React.FC<
  { date?: SimpleDate } & Partial<NumberCardProps>
> = ({ date, ...props }) => {
  const { t } = useTranslation();

  return (
    <NumberCard
      title={t("component.analytics.balance")}
      icon={<CardStackIcon className="h-5 w-5 text-muted-foreground" />}
      subtext={
        date &&
        t("common.dateSingle", {
          date: simpleDateToDate(date).toLocaleDateString(i18n.language, {
            day: "numeric",
            month: "long",
            year: "numeric",
          }),
        })
      }
      {...props}
    />
  );
};
