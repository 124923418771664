'use client'

import { Button } from '@/components/ui/button'
import { CheckIcon, CopyIcon } from '@radix-ui/react-icons'
import { useCopyToClipboard } from './hooks/useCopyToClipboard'
import { cn } from '@/lib/utils'

export function ChatMessageActions({ message, className, ...props }) {
  const { isCopied, copyToClipboard } = useCopyToClipboard({ timeout: 2000 })

  const onCopy = () => {
    if (isCopied) return
    copyToClipboard(Array.isArray(message.content) ? message.content[0] : message.content)
  }

  return (
    <div
      className={cn(
        'flex items-center justify-end transition-opacity group-hover:opacity-100 md:absolute md:-right-10 md:-top-2 md:opacity-0',
        className
      )}
      {...props}
    >
      <Button variant="ghost" size="icon" onClick={onCopy}>
        {isCopied ? <CheckIcon /> : <CopyIcon />}
        <span className="sr-only">Copy message</span>
      </Button>
    </div>
  )
}
