import { reduxApi } from "../reduxApi";
import { Filter, FilterResult } from "@/api/types";
import { BankTransaction } from "@/models/bankTransaction";
import { BankAccount } from "@/models/bankAccount";
import { BankInstitution } from "@/models/bankInstitution";
import { GoCardlessRequisition } from "@/models/goCardlessRequisition";

const bankApi = reduxApi.injectEndpoints({
  endpoints: (builder) => ({
    bankTransactionFilter: builder.query<
      FilterResult<BankTransaction>,
      Filter<BankTransaction>
    >({
      query: (body) => ({
        url: "banktransaction/filter",
        method: "POST",
        body,
      }),
      providesTags: ["Bank"],
    }),
    getBankTransaction: builder.query<BankTransaction, { id: string }>({
      query: ({ id }) => `banktransaction/${id}`,
      providesTags: ["Bank"],
    }),
    bankAccountsFilter: builder.query<
      FilterResult<BankAccount>,
      Filter<BankAccount>
    >({
      query: (body) => ({
        url: "bankaccount/filter",
        method: "POST",
        body,
      }),
      providesTags: ["Bank"],
    }),
    bankAccountDisable: builder.mutation<BankAccount, BankAccount>({
      query: ({ id }) => ({
        url: `bankaccount/${id}/disable`,
        method: "POST",
      }),
      invalidatesTags: ["Bank"],
    }),
    bankAccountEnable: builder.mutation<BankAccount, BankAccount>({
      query: ({ id }) => ({
        url: `bankaccount/${id}/enable`,
        method: "POST",
      }),
      invalidatesTags: ["Bank"],
    }),
    listGoCardlessBankInstitutions: builder.query<BankInstitution[], void>({
      query: () => "gocardless/institution/",
    }),
    createGoCardlessRequisition: builder.mutation<
      GoCardlessRequisition,
      { institutionId: string; maxHistoricalDays?: number }
    >({
      query: (body) => ({
        url: "gocardless/requisition/",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Bank"],
    }),
    // get requisition
    getGoCardlesRequisition: builder.query<
      GoCardlessRequisition,
      { id: string }
    >({
      query: (arg) => "gocardless/requisition/" + arg.id,
      providesTags: ["Bank"],
    }),
    //sync requisition
    goCardlesRequisitionSync: builder.mutation<
      GoCardlessRequisition,
      { id: string }
    >({
      query: (arg) => ({
        url: "gocardless/requisition/" + arg.id + "/sync",
        method: "POST",
      }),
      invalidatesTags: ["Bank"],
    }),
    //list requisitions
    listGoCardlessRequisitions: builder.query<GoCardlessRequisition[], void>({
      query: () => "gocardless/requisition/",
      providesTags: ["Bank"],
    }),
    // get institution
    getGoCardlessBankInstitution: builder.query<
      BankInstitution,
      { id: string }
    >({
      query: (arg) => "gocardless/institution/" + arg.id,
      providesTags: ["Bank"],
    }),
    //delete requisition
    goCardlesRequisitionDelete: builder.mutation<string, { id: string }>({
      query: (arg) => ({
        url: "gocardless/requisition/" + arg.id,
        method: "DELETE",
      }),
      invalidatesTags: ["Bank"],
    }),
  }),
});

export const {
  useBankTransactionFilterQuery,
  useLazyBankTransactionFilterQuery,
  useGetBankTransactionQuery,
  useBankAccountsFilterQuery,
  useBankAccountDisableMutation,
  useBankAccountEnableMutation,
  useListGoCardlessBankInstitutionsQuery,
  useCreateGoCardlessRequisitionMutation,
  useGetGoCardlesRequisitionQuery,
  useGoCardlesRequisitionSyncMutation,
  useListGoCardlessRequisitionsQuery,
  useGetGoCardlessBankInstitutionQuery,
  useGoCardlesRequisitionDeleteMutation,
} = bankApi;
