import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import { Title } from "@/components/text/Title";
import { useLoggedIn } from "@/feature/auth/ProtectedRoutes";
import { CashNumberCard } from "@/feature/dashboard/components/charts/number/CashNumberCard";
import { IncomeNumberCard } from "@/feature/dashboard/components/charts/number/IncomeNumberCard";
import { ExpensesNumberCard } from "@/feature/dashboard/components/charts/number/ExpensesNumberCard";
import { RunwayNumberCard } from "@/feature/dashboard/components/charts/number/RunwayNumberCard";
import { simpleDateFrom, simpleDateToDate } from "@/models/document";
import { useFinanceQuery } from "@/api/endpoints/analyticsApi";
import { endOfDay, startOfDay } from "@/util/date";
import {
  DateUnits,
  DateUnitType,
} from "@/feature/dashboard/components/charts/util/date";
import AreaChartCard from "@/feature/dashboard/components/charts/areachart/AreaChartCard";

const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useLoggedIn();
  const today = useMemo(() => new Date(), []);

  const [dateRange, setDateRange] = React.useState<{
    from: Date;
    to: Date;
    unit: DateUnitType;
  }>(() => {
    const from = new Date();
    from.setMonth(from.getMonth() - 3);
    return {
      from: from,
      to: new Date(),
      unit: "month",
    };
  });

  const { data, isLoading } = useFinanceQuery({
    ranges: [
      {
        from: simpleDateFrom(dateRange.from),
        to: simpleDateFrom(dateRange?.to ?? today),
      },
    ],
    fit: true,
  });

  let dateRangeMonth = data?.data[0]
    ? DateUnits["month"].unitBetweenDates(
        startOfDay(simpleDateToDate(data?.data[0].from)),
        endOfDay(simpleDateToDate(data?.data[0].to)),
      )
    : 1;

  let dateRangeInUnit = data?.data[0]
    ? DateUnits[dateRange.unit].unitBetweenDates(
        startOfDay(simpleDateToDate(data?.data[0].from)),
        endOfDay(simpleDateToDate(data?.data[0].to)),
      )
    : 1;

  const incomePerUnit = data ? data.data[0].income / dateRangeInUnit : 0;
  const expensesPerUnit = data ? data.data[0].expenses / dateRangeInUnit : 0;

  return (
    <HorizontalFlex className="flex flex-col">
      <Title>
        {t("component.dashboard.main.welcomeMessage", {
          firstname: user.firstname,
        })}
      </Title>

      <VerticalFlex className="gap-4 p-0">
        <HorizontalFlex className="gap-4">
          <CashNumberCard
            date={data?.data[0].to}
            totalAmount={data?.data[0].balance}
            change={0}
            isLoading={isLoading}
          />
          <IncomeNumberCard
            range={data && data.data[0]}
            unit={dateRange.unit}
            totalAmount={incomePerUnit}
            change={0}
            isLoading={isLoading}
          />
          <ExpensesNumberCard
            range={data && data.data[0]}
            unit={dateRange.unit}
            totalAmount={expensesPerUnit}
            change={0}
            isLoading={isLoading}
          />
          <RunwayNumberCard
            unit={dateRange.unit}
            totalAmount={
              data
                ? data?.data[0].balance /
                  (data.data[0].expenses / dateRangeMonth)
                : 0
            }
            isLoading={isLoading}
          />
        </HorizontalFlex>

        <VerticalFlex className="gap-4">
          <AreaChartCard onDateRangeChange={setDateRange} range={dateRange} />
          {/*<HorizontalFlex className="gap-4">*/}
          {/*  <div className="w-full sm:w-full md:w-1/2 lg:w-1/3 xl:w-1/2">*/}
          {/*    <HorizontalChartCard />*/}
          {/*  </div>*/}
          {/*  <div className="w-full sm:w-full md:w-1/2 lg:w-1/3 xl:w-1/2">*/}
          {/*    <UnpaidInvoicesCard />*/}
          {/*  </div>*/}
          {/*</HorizontalFlex>*/}
        </VerticalFlex>
      </VerticalFlex>
    </HorizontalFlex>
  );
};

export default Dashboard;
