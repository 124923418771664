import { Navigate, Outlet } from "react-router-dom";
import { createContext, useContext } from "react";
import { UserCompany } from "@/models/userCompany";
import { User } from "@/models/user";
import { Company } from "@/models/company";
import { AuthenticatedUser, useAuth } from "@/feature/auth/AuthProvider";
import Spinner from "@/components/loading/spinner";

const LoggedInContext = createContext<AuthenticatedUser>({
  user: {} as User,
  activeCompany: {} as Company,
  activeIdentity: {} as UserCompany,
  identities: [] as UserCompany[],
});

export const useLoggedIn = () => {
  return useContext(LoggedInContext);
};

export const ProtectedRoutes = () => {
  const { userData, isLoading } = useAuth();
  if (!isLoading && !userData) {
    return <Navigate to="/login" replace />;
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <LoggedInContext.Provider value={userData as AuthenticatedUser}>
      <Outlet />
    </LoggedInContext.Provider>
  );
};
