import React from "react";
import { BiDocument } from "@/models/document";
import DeleteButton from "@/feature/documents/components/Card/CardHeader/DeleteButton";
import EditButton from "@/feature/documents/components/Card/CardHeader/EditButton";
import DownloadButton from "@/feature/documents/components/Card/CardHeader/DownloadButton";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { Check, EllipsisVerticalIcon } from "lucide-react";
import { cn } from "@/lib/utils";

import { useDocumentContext } from "@/feature/documents/DocumentContextProvider";
import { useConfirmModal } from "@/components/modal/useConfirmModal";
import { t } from "i18next";
import { useDeleteBookingTransactionMutation } from "@/api/endpoints/bookingApi";
import { toast } from "@/components/ui/use-toast";
import SmallTransactionPreview from "@/feature/documents/components/Card/CardBody/SmallTransactionPreview";

function DocumentActions({
  biDocument,
  asDropdown = false,
  className,
}: {
  biDocument: BiDocument;
  asDropdown?: boolean;
  className?: string;
}) {
  const { mode, setMode } = useDocumentContext();
  const isBooked = !(biDocument.bookingTransactions?.length === 0);
  const { confirmDelete } = useConfirmModal();
  const [deleteTransaction] = useDeleteBookingTransactionMutation();

  const confirmUnlink = () =>
    confirmDelete({
      text: (
        <div>
          {t("model.document.isBooked")}
          {biDocument.bookingTransactions?.map(
            (transaction) =>
              transaction.argument.match?.transactionIds !== undefined && (
                <SmallTransactionPreview
                  transactionIds={transaction.argument.match.transactionIds}
                />
              ),
          )}
        </div>
      ),
      onAction: async () => {
        const allTransactions = biDocument.bookingTransactions;
        if (allTransactions) {
          allTransactions.forEach((transaction) => {
            let res = deleteTransaction({ id: transaction.id });
            if ("data" in res) {
              toast({
                title: t("common.successDelete"),
                icon: Check,
              });
            }
          });
        }
      },
    });

  const buttons = (
    <>
      <DeleteButton
        document={biDocument}
        beforeDelete={() => {
          if (isBooked) {
            confirmUnlink();
            return false;
          }
          return true;
        }}
      />
      <DownloadButton biDocument={biDocument} />
      <EditButton
        isEditing={mode === "edit"}
        onClick={() => {
          if (isBooked) {
            confirmUnlink();
            return;
          }

          const prevMode = mode;
          if (prevMode === "edit") {
            setMode("small");
            return;
          }
          if (
            prevMode === "small" ||
            prevMode === "preview" ||
            prevMode === "edit-saved"
          ) {
            setMode("edit");
            return;
          }
        }}
      />
    </>
  );

  return (
    <div className={cn(className)}>
      {asDropdown ? (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button size={"icon"} variant="ghost" className={"group"}>
              <EllipsisVerticalIcon
                className={
                  "text-muted-foreground transition-all group-hover:rotate-90 group-hover:text-foreground"
                }
              />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>{buttons}</DropdownMenuContent>
        </DropdownMenu>
      ) : (
        <>{buttons}</>
      )}
    </div>
  );
}

export default DocumentActions;
