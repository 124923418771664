import { Skeleton } from "@/components/ui/skeleton";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { ReactNode } from "react";

const DocumentField = ({
  label,
  value,
  className = "",
  processing = false,
  width = 10,
  showLabel = false,
}: {
  label: string;
  value?: ReactNode;
  className?: string;
  processing?: boolean;
  width?: number;
  showLabel?: boolean;
}) => {
  if (processing) {
    return <Skeleton className={`h-4`} style={{ width: `${width}em` }} />;
  }

  return value ? (
    <Tooltip>
      <TooltipTrigger asChild>
        <>
          {showLabel && <span className="font-light opacity-25">{label}</span>}
          <span className={className}>{value}</span>
        </>
      </TooltipTrigger>
      <TooltipContent>
        <p>{label}</p>
        <span className={"text-xs text-muted-foreground"}>{value}</span>
      </TooltipContent>
    </Tooltip>
  ) : (
    <span className={cn("font-light opacity-25", className)}>{label}</span>
  );
};

export default DocumentField;
