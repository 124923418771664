import { useTranslation } from "react-i18next";
import {
  FormSimpleSelect,
  FormSimpleSelectGroup,
} from "@/components/form/FormSimpleSelect";
import { Spinner } from "@react-pdf-viewer/core";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { biDocumentSchema } from "@/models/EditDocumentModel";
import { UseFormReturn, useWatch } from "react-hook-form";
import { TypeOf } from "zod";
import { useGetAlLCategoriesQuery } from "@/api/endpoints/documentApi";
import { BiDocument, CategorizationResponse } from "@/models/document";
import React, { useEffect, useMemo } from "react";

export const EditCategorySelection: React.FC<{
  edit?: boolean;
  form: UseFormReturn<TypeOf<typeof biDocumentSchema>>;
  document: BiDocument;
}> = ({ edit, form, document }) => {
  const { t } = useTranslation();

  const documentCategory = useWatch({ name: "category" });
  const documentKind = useWatch({ name: "kind" });

  const { data, isLoading } = useGetAlLCategoriesQuery(document.id);

  let invalid =
    data &&
    !data
      .filter((d) => documentKind && d.kind.includes(documentKind))
      .find((d) => d.key === documentCategory);

  useEffect(() => {
    if (invalid) {
      form.setValue("category", "");
    }
  }, [invalid]);

  const options: FormSimpleSelectGroup[] = useBuildGroups(
    data?.filter((d) => documentKind && d.kind.includes(documentKind)) ?? [],
  );

  return (
    <FormItem className={"py-4"}>
      <FormLabel className={"text-muted-foreground"}>
        {t("model.document.category")}
      </FormLabel>
      <FormControl className={"flex w-full flex-col"}>
        {isLoading ? (
          <Spinner />
        ) : (
          <FormSimpleSelect
            invalidPlaceholder={
              documentCategory && invalid
                ? t("model.document.wrongCategorySelected")
                : undefined
            }
            placeholder={t("model.document.category_placeholder")}
            edit={edit}
            form={form}
            name={"category"}
            options={options}
          />
        )}
      </FormControl>
      <FormField name={"category"} render={() => <FormMessage />} />
    </FormItem>
  );
};

function useBuildGroups(d: CategorizationResponse[]): FormSimpleSelectGroup[] {
  const { t } = useTranslation();

  return useMemo(() => {
    const withScore = d.filter((d) => d.score > 0);
    const withoutScore = d.filter((d) => d.score <= 0);

    const groupMap: { [name: string]: CategorizationResponse[] } = {};
    for (const item of withoutScore) {
      let cat = groupMap[item.category] ?? [];
      groupMap[item.category] = cat;
      cat.push(item);
      cat.sort((a, b) => a.name.localeCompare(b.name));
    }
    const groupList = new Array(...Object.entries(groupMap));
    groupList.sort(([a], [b]) => a.localeCompare(b));

    const groups: FormSimpleSelectGroup[] = [];
    if (withScore.length > 0) {
      groups.push({
        label: t("model.document.category_suggestion"),
        options: withScore.map((s) => ({
          value: s.key,
          label: s.name,
        })),
      });
    }

    for (const [name, options] of groupList) {
      groups.push({
        label: name,
        options: options.map((s) => ({
          value: s.key,
          label: s.name,
        })),
      });
    }

    return groups;
  }, [d]);
}
