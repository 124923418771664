import React, { Fragment } from "react";
import { Progress } from "@/components/ui/progress";
import {
  ArrowUpFromLine,
  Ban,
  Check,
  CircleX,
  FileUp,
  HourglassIcon,
  Upload,
} from "lucide-react";
import { useTranslation } from "react-i18next";
import { FileUploadProgress } from "@/components/upload/UploadContextProvider";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";

export function UploadInfoCard({ uploads }: { uploads: FileUploadProgress[] }) {
  const { t } = useTranslation();

  return (
    <VerticalFlex className="gap-4 pt-4">
      <VerticalFlex className="space-y-2">
        <h4 className="text-muted-foreground">{t("common.uploadStatus")}</h4>
      </VerticalFlex>
      <VerticalFlex>
        {[...uploads].reverse().map((upload) => (
          <Fragment key={upload.id}>
            <HorizontalFlex className={"flex-1"} align={"center"}>
              <FileUp size={24} />
              <div className={"w-[100px] flex-1"}>
                <div className="file-name overflow-hidden text-ellipsis whitespace-nowrap">
                  {upload.file.name}
                </div>
                <Progress value={upload.progress} />
              </div>
              {upload.status === "loadstart" && <ArrowUpFromLine size={24} />}
              {upload.status === "progress" && <Upload size={24} />}
              {upload.status === "load" && <Check size={24} />}
              {upload.status === "error" && <Ban size={24} />}
              {upload.status === "timeout" && <HourglassIcon size={24} />}
              {upload.status === "abort" && <CircleX size={24} />}
            </HorizontalFlex>
          </Fragment>
        ))}
      </VerticalFlex>
    </VerticalFlex>
  );
}
