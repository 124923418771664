import React from "react";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ChevronDown, Cross } from "lucide-react";
import { cn } from "@/lib/utils";
import {
  useBankTransactionFilterQuery,
  useLazyBankTransactionFilterQuery,
} from "@/api/endpoints/bankApi";
import { Filter, FilterInner } from "@/api/types";
import { BankTransaction } from "@/models/bankTransaction";
import { BottomFloat } from "@/components/float/BottomFloat";
import Spinner from "@/components/loading/spinner";
import Papa from "papaparse";
import { useToast } from "@/components/ui/use-toast";
import { useTranslation } from "react-i18next";
import { formatSimpleDate } from "@/util/format";

function ExportAsButton(props: {
  onClick: () => void;
  exportType: "CSV";
  loading?: boolean;
}) {
  const { t } = useTranslation();

  return (
    <Button onClick={props.onClick} variant="ghost" className="">
      {props.loading && <Spinner />}
      {t(`common.exportAs.${props.exportType}`)}
    </Button>
  );
}

const Export: React.FC<{
  filterQuery: Filter<BankTransaction>;
  defaultSelected: boolean;
  selectionInversion: string[];
}> = ({ defaultSelected, selectionInversion, filterQuery }) => {
  let disabled = !(defaultSelected || selectionInversion.length > 0);
  const { toast } = useToast();
  const { t, i18n } = useTranslation();

  let selectionQuery: FilterInner<BankTransaction>;
  if (defaultSelected) {
    selectionQuery = {
      kind: "not",
      children: [
        {
          kind: "oneof",
          attribute: "id",
          value: selectionInversion,
        },
      ],
    };
  } else {
    selectionQuery = {
      kind: "oneof",
      attribute: "id",
      value: selectionInversion,
    };
  }

  let fullQuery = {
    kind: "and",
    children: [filterQuery, selectionQuery],
    offset: 0,
  };

  const [fetchBankTransactions, fetchBankTransactionResult] =
    useLazyBankTransactionFilterQuery();

  const { data, isLoading } = useBankTransactionFilterQuery(
    {
      ...fullQuery,
      limit: 0,
    },
    {
      skip: disabled,
    },
  );

  const triggerCSVExport = async () => {
    let allTransactions = await fetchBankTransactions({
      ...fullQuery,
      limit: 1000 * 1000 * 1000,
      sort: "bookingDate",
      direction: "desc",
    });

    if (!allTransactions.data) {
      return;
    }

    if (allTransactions.data.total !== allTransactions.data.items.length) {
      console.error("Expectation failed");
      toast({
        icon: Cross,
        title: t("error.bug"),
      });
    }

    let csvRows = allTransactions.data.items.map((item) => {
      let map: { [key: string]: string | undefined } = {};
      map[t("model.transaction.valueDate")] = formatSimpleDate(item.valueDate);
      map[t("model.transaction.bookingDate")] = formatSimpleDate(
        item.bookingDate,
      );
      map[t("model.transaction.bankAccount")] =
        item.bankAccount?.bankName + " " + item.bankAccount?.productName;
      map[t("model.transaction.accountIBAN")] = item.accountIBAN;
      map[t("model.transaction.otherPartyName")] = item.otherPartyName;
      map[t("model.transaction.otherPartyIBAN")] = item.otherPartyIBAN;
      map[t("model.transaction.transactionAmount") + " (EUR)"] =
        Intl.NumberFormat(i18n.language, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(item.transactionAmount / 100);

      map[t("model.transaction.remittanceInformation")] =
        item.remittanceInformation;

      map[t("model.transaction.mandateId")] = item.mandateId;

      map[t("model.transaction.status.self")] = t(
        "model.transaction.status." + item.status,
      );

      return map;
    });

    const csv = Papa.unparse(csvRows, { header: true });
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;

    const today = new Date();
    const dateStr =
      today.getFullYear() +
      "-" +
      String(today.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(today.getDate()).padStart(2, "0");

    link.setAttribute(
      "download",
      t("model.transaction.self_many").toLowerCase() + "-" + dateStr + ".csv",
    );

    link.click();
    URL.revokeObjectURL(url);
  };

  const [open, setOpen] = React.useState(false);
  return (
    <>
      <DropdownMenu open={open} onOpenChange={setOpen}>
        <DropdownMenuTrigger asChild disabled={disabled}>
          <Button
            disabled={disabled || !data?.total}
            variant="ghost"
            className={cn(
              open &&
                "rounded-m z-50 min-w-[8rem] overflow-hidden bg-popover p-1 text-popover-foreground shadow-md",
            )}
          >
            {t("common.export")}{" "}
            <ChevronDown
              size={16}
              className={cn(
                "ml-1 transition-transform duration-200",
                open && "rotate-180 transform",
              )}
            />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="flex flex-1 flex-col border-0">
          <ExportAsButton
            onClick={triggerCSVExport}
            exportType={"CSV"}
            loading={fetchBankTransactionResult.isLoading}
          />
        </DropdownMenuContent>
      </DropdownMenu>
      <BottomFloat open={!disabled} className={"text-center"}>
        {isLoading && <Spinner size={10} />}
        {!isLoading &&
          t("component.transactions.export.transactionSelected", {
            count: data?.total,
          })}
      </BottomFloat>
    </>
  );
};

export default Export;
