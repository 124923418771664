import { createContext, ReactNode, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { cn } from "@/lib/utils";
import { VerticalFlex } from "@/components/layout/Flex";
import { Button } from "@/components/ui/button";

interface ModalContextType {
  showModal: (content: ModalDescription) => ModalHandle;
}

interface ModalHandle {
  id: string;
  close: () => void;
}

export const ModalContext = createContext<ModalContextType>({
  showModal: (_: ModalDescription) => {
    throw new Error("Not Implemented");
  },
});

export interface ModalDescription {
  content?: ReactNode | ((args: { close: () => void }) => ReactNode);
  title?: ReactNode;
  description?: ReactNode;
  className?: string;
  hint?: string;
}

export interface ModalDescriptionWithId {
  id: string;
  description: ModalDescription;
}

export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [modals, setModals] = useState<ModalDescriptionWithId[]>([]);

  const closeModal = (id: string) => {
    setModals((m) => m.filter((i) => i.id !== id));
  };

  const showModal = (description: ModalDescription) => {
    // TODO: Make modal close if hook is unmounted.
    // This leads to bugs, where the modal content still accesses unmounted state
    const id = Math.random().toString(36);
    setModals([
      ...modals,
      {
        id,
        description,
      },
    ]);

    return { id, close: () => closeModal(id) };
  };

  return (
    <ModalContext.Provider
      value={{
        showModal,
      }}
    >
      {modals.map((m) => (
        <ModalDialog desc={m} onClose={() => closeModal(m.id)} key={m.id} />
      ))}
      {children}
    </ModalContext.Provider>
  );
};

const ModalDialog = ({
  desc,
  onClose,
  className,
}: {
  desc: ModalDescriptionWithId;
  onClose: () => void;
  className?: string;
}) => {
  let contentFn: (args: { close: () => void }) => ReactNode;
  if (typeof desc.description.content == "function") {
    contentFn = desc.description.content;
  } else {
    // if it's not a function it must be the react element.
    contentFn = () => desc.description.content as any;
  }

  const [showHint, setShowHint] = useState<boolean>(true);

  return (
    <Dialog
      open={true}
      onOpenChange={(open) => {
        !open && onClose();
      }}
    >
      <DialogContent
        className={cn("p-[-1.5rem]", className, desc.description.className)}
      >
        <VerticalFlex className={"relative p-6"}>
          {desc.description.hint && showHint && (
            <VerticalFlex
              gap={8}
              align={"center"}
              justify={"center"}
              className={
                "absolute left-0 top-0 z-10 h-full w-full bg-background/90 p-8 backdrop-blur-sm"
              }
            >
              <span className={"text-2xl font-semibold"}>Hinweis</span>
              <span className={"text-center text-lg font-thin"}>
                {desc.description.hint}
              </span>
              <div>
                <Button variant={"default"} onClick={() => setShowHint(false)}>
                  Verstanden
                </Button>
              </div>
            </VerticalFlex>
          )}

          {desc.description.title && (
            <DialogHeader className={"gap-2"}>
              <DialogTitle>{desc.description.title}</DialogTitle>
              {desc.description.description && (
                <DialogDescription className={"text-xs"}>
                  {desc.description.description}
                </DialogDescription>
              )}
            </DialogHeader>
          )}
          {contentFn({
            close: onClose,
          })}
        </VerticalFlex>
      </DialogContent>
    </Dialog>
  );
};
