import { Link } from "react-router-dom";
import logo from "@/assets/logo_white_no_bg.png";
import logo_icon from "@/assets/logo_white_no_bg_icon.png";

const Logo = ({ expanded }: { expanded?: boolean }) => {
  return (
    <Link key="logo" to="/" className="m-auto flex items-center gap-4">
      <div className="flex h-8 items-center">
        <img src={logo_icon} alt="bimetrics-logo" className="h-8 w-auto" />
        {expanded && <img src={logo} alt="bimetrics" className="h-8 w-auto" />}
      </div>
    </Link>
  );
};

export default Logo;
