import { reduxApi } from "@/api/reduxApi";
import { BiDocument } from "@/models/document";
import { ContactWithId } from "@/models/contact";

const documentProcessingApi = reduxApi.injectEndpoints({
  endpoints: (builder) => ({
    getDocumentMatchSuggestionsByBankTransaction: builder.query<
      [{ document: BiDocument; score: number }],
      { id: string }
    >({
      query: (arg) =>
        "documentprocessing/banktransaction/" + arg.id + "/matches",
      providesTags: ["Document"],
    }),
    getContactSuggestionsByDocument: builder.query<
      [{ confidence: number; contact: ContactWithId }],
      { docId: string }
    >({
      query: (arg) => "documentprocessing/" + arg.docId + "/matches",
      providesTags: ["Contact"],
    }),
  }),
});

export const {
  useGetDocumentMatchSuggestionsByBankTransactionQuery,
  useGetContactSuggestionsByDocumentQuery,
} = documentProcessingApi;
