import { useChat } from "ai/react";
import { cn } from "@/lib/utils";
import { ChatList } from "./ChatList";
import { ChatPanel } from "./ChatPanel";
import { EmptyScreen } from "./EmptyScreen";
import { ChatScrollAnchor } from "./ChatScrollAnchor";
import { useEffect } from "react";
import { GLOBAL_API_TOKEN } from "@/api/reduxApi";

export function Chat({ id, initialMessages, className }) {
  const api = process.env.REACT_APP_API_URL + "chatai/chat";
  const {
    messages,
    append,
    reload,
    stop,
    isLoading,
    input,
    setInput,
    setMessages,
  } = useChat({
    api,
    initialMessages,
    id,
    headers: {
      Authorization: `Bearer ${GLOBAL_API_TOKEN}`,
    },
    body: { id: "useCompanyIdInsteadOfUserId" },
    onResponse(response) {
      if (response.status === 401) {
        console.error(response.statusText);
      }
    },
    onFinish(message) {
      console.log("Message received from server:", message);
    },
    onError(error) {
      console.error("Error in chat:", error);
    },
  });

  useEffect(() => {
    console.log("Updated messages:", messages);
  }, [messages]);

  const chatContainerStyles = {
    overflowY: "auto",
    overflowX: "hidden",
    scrollbarWidth: "thin",
    scrollbarColor: "rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.1)",
  };

  const chatContainerScrollbarStyles = `
    .chat-container::-webkit-scrollbar {
      width: 12px;
    }
    .chat-container::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 10px;
    }
    .chat-container::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 10px;
    }
  `;

  return (
    <>
      <style>{chatContainerScrollbarStyles}</style>
      <div
        className={cn(
          "chat-container h-[calc(100vh-300px)] p-4 pb-[10px] md:p-8 md:pt-10", // Tailwind classes for padding and height
          "sm:p-2 md:p-4 lg:p-8", // Responsive padding adjustments
          className,
        )}
        style={chatContainerStyles}
      >
        {messages.length ? (
          <>
            <ChatList messages={messages} />
            <ChatScrollAnchor trackVisibility={isLoading} />
          </>
        ) : (
          <EmptyScreen setInput={setInput} />
        )}
      </div>

      <ChatPanel
        id={id}
        isLoading={isLoading}
        stop={stop}
        append={append}
        reload={reload}
        messages={messages}
        input={input}
        setInput={setInput}
      />
    </>
  );
}
