import { Contact, CONTACT_NO_MAX, CONTACT_NO_MIN } from "@/models/contact";

export function removeDuplicates<T>(arr: T[]): T[] {
  return Array.from(new Set(arr));
}

export function removeDuplicatesByKey<T, K>(
  arr: T[],
  key: (item: T) => K,
): T[] {
  const seen = new Set<K>(); // Set to store unique keys
  return arr.filter((item) => {
    const keyValue = key(item);
    if (seen.has(keyValue)) {
      return false; // Duplicate, exclude it
    }
    seen.add(keyValue); // Mark key as seen
    return true; // Include in the result
  });
}

//TODO @tobias please remove this function from here. It does not fix in this domain?!
export const calculateNextUniqueCustomerNumber = (
  allContacts: Contact[],
): number => {
  const usedNums = new Set(
    allContacts.map((contact) => contact.customerNumber),
  );

  for (let maybe = CONTACT_NO_MIN; maybe <= CONTACT_NO_MAX; maybe++) {
    if (!usedNums.has(maybe)) {
      return maybe;
    }
  }

  throw new Error("No valid customer number available");
};
