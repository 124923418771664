import { UseFormReturn } from "react-hook-form";
import { Contact } from "@/models/contact";
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { t } from "i18next";
import { Input } from "@/components/ui/input";
import { FlexOne } from "@/components/layout/Flex";

function PaymentFormFields(props: { form: UseFormReturn<Contact> }) {
  const form = props.form;

  return (
    <div className="grid grid-cols-3 gap-4">
      <FormField
        control={form.control}
        name="paymentInfo.iban"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t("component.contacts.paymentInfo.iban")}</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>

            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="paymentInfo.bic"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t("component.contacts.paymentInfo.bic")}</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>

            <FormMessage />
          </FormItem>
        )}
      />
      <FlexOne />
      <FormField
        control={form.control}
        name="paymentInfo.taxNumber"
        render={({ field }) => (
          <FormItem>
            <FormLabel>
              {t("component.contacts.paymentInfo.taxNumber")}
            </FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>

            <FormMessage />
          </FormItem>
        )}
      />{" "}
      <FormField
        control={form.control}
        name="paymentInfo.vatId"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t("component.contacts.paymentInfo.vatId")}</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>

            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="paymentInfo.leitwegId"
        render={({ field }) => (
          <FormItem>
            <div className="flex items-center gap-2">
              <FormLabel>
                {t("component.contacts.paymentInfo.leitwegId")}
              </FormLabel>
              <FormDescription>
                {t("component.contacts.leitwegIdDesc")}
              </FormDescription>
            </div>
            <FormControl>
              <Input {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
}

export default PaymentFormFields;
