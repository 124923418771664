import React, { FC, useEffect, useState } from "react";
import { z } from "zod";
import { BiDocument, BiDocumentKind, SimpleDate } from "@/models/document";
import { useDocumentEditMutation } from "@/api/endpoints/documentApi";
import { toast } from "@/components/ui/use-toast";
import { Check } from "lucide-react";
import { SaveBar } from "@/components/form/SaveBar";
import { useForm, UseFormReturn, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FlexOne, HorizontalFlex } from "@/components/layout/Flex";
import { cn } from "@/lib/utils";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { FormSimpleText } from "@/components/form/FormSimpleText";
import {
  formatNumberFancy,
  FormSimpleMoney,
} from "@/components/form/FormSimpleMoney";
import { FormSimpleSelect } from "@/components/form/FormSimpleSelect";
import { FormSimpleDate } from "@/components/form/FormSimpleDate";
import { FormSimpleHeading } from "@/components/form/FormSimpleHeading";
import {
  InvoiceBadge,
  ReceiptBadge,
} from "@/feature/documents/components/Badge";
import { Form, FormLabel } from "@/components/ui/form";
import FormSimpleDateRange from "@/feature/documents/components/Card/CardBody/EditForm/FormSimpleDateRange";
import HorizontalGradientLine from "@/components/layout/HorizontalGradientLine";
import { biDocumentSchema } from "@/models/EditDocumentModel";
import { formatSimpleDate } from "@/util/format";
import { EditCategorySelection } from "@/feature/documents/components/Card/CardBody/EditForm/EditCategorySelection";
import FormContactNameComboBox from "@/feature/documents/components/Card/CardBody/EditForm/FormContactNameComboBox";
import { useDocumentContext } from "@/feature/documents/DocumentContextProvider";

type BiDocumentSchema = z.infer<typeof biDocumentSchema>;

interface EditDocumentCardBodyProps {
  onCancel: () => void;
  biDocument: BiDocument;
}

const EditDocumentCardBody: React.FC<EditDocumentCardBodyProps> = ({
  onCancel,
  biDocument,
}) => {
  const defaultValues = {
    kind: biDocument.kind || BiDocumentKind.Invoice,
    invoiceNumber: biDocument.invoiceNumber || "",
    invoiceDate: biDocument.invoiceDate,

    dueDate: biDocument.dueDate,
    performancePeriodFrom: biDocument.performancePeriodFrom,
    performancePeriodTo: biDocument.performancePeriodTo,
    issuer: {
      name: biDocument.issuer.name || "",
      address: biDocument.issuer.address || "",
      taxId: biDocument.issuer.taxId || "",
      iban: biDocument.issuer.iban || "",
      customerNumber: biDocument.issuer.customerNumber,
    },
    recipient: {
      name: biDocument.recipient.name || "",
      address: biDocument.recipient.address || "",
      taxId: biDocument.recipient.taxId || "",
      iban: biDocument.recipient.iban || "",
      customerNumber: biDocument.recipient.customerNumber,
    },
    totalTaxAmount: biDocument.totalTaxAmount,
    totalAmount: biDocument.totalAmount,
    category: biDocument.category || "",
    deliveryDate: biDocument.deliveryDate,
    paymentTerms: biDocument.paymentTerms || "",
  };
  const form = useForm<BiDocumentSchema>({
    resolver: zodResolver(biDocumentSchema),
    defaultValues,
  });

  const [documentEdit, documentEditResult] = useDocumentEditMutation();
  const { setMode } = useDocumentContext();

  return (
    <>
      <Form {...form}>
        <form
          onSubmit={(e) => {
            form.handleSubmit(
              (v) => {
                documentEdit({
                  ...biDocument,
                  ...(v as any),
                }).then((result) => {
                  if ("data" in result) {
                    toast({
                      title: t("common.successSave"),
                      icon: Check,
                    });
                    setMode("edit-saved");
                  }
                });
              },
              (errors) => {
                console.error(errors);
              },
            )(e);
          }}
          className={cn("flex h-full flex-1 flex-col space-y-2")}
        >
          <HorizontalFlex align={"end"}>
            <FormSimpleHeading
              label={t("model.document.invoiceNumber")}
              edit={true}
              form={form}
              name={"invoiceNumber"}
              placeholder={t("model.document.invoiceNumber")}
            />
            <FlexOne />
            <FormSimpleSelect
              edit={true}
              form={form}
              name={"kind"}
              options={[
                {
                  label: t("model.document.kind.self"),
                  options: [
                    {
                      value: BiDocumentKind.Invoice,
                      label: <InvoiceBadge />,
                    },
                    {
                      value: BiDocumentKind.Receipt,
                      label: <ReceiptBadge />,
                    },
                  ],
                },
              ]}
            />
          </HorizontalFlex>

          <InvoiceBody edit={true} form={form} document={biDocument} />

          <FlexOne />
          <HorizontalGradientLine />
          <SaveBar
            result={documentEditResult}
            className={"mx-auto pt-4"}
            onAbort={() => {
              onCancel();
              form.reset();
            }}
          />
        </form>
      </Form>
    </>
  );
};

export default EditDocumentCardBody;

const InvoiceBody: FC<{
  document: BiDocument;
  edit: boolean;
  form: UseFormReturn<z.infer<typeof biDocumentSchema>>;
}> = ({ form, document, edit }) => {
  const { t } = useTranslation();

  let [totalAmount, totalTaxAmount, formInvoiceDate, documentKind] = useWatch({
    name: ["totalAmount", "totalTaxAmount", "invoiceDate", "kind"],
  });
  let invoiceDate = formInvoiceDate
    ? {
        year: formInvoiceDate.year ?? 0,
        month: formInvoiceDate.month ?? 0,
        day: formInvoiceDate.day ?? 0,
      }
    : undefined;
  let totalNetAmount = Math.round((totalAmount || 0) - (totalTaxAmount || 0));

  const issuer = documentKind !== BiDocumentKind.Invoice;

  return (
    <>
      <FormContactNameComboBox form={form} />

      <FormSimpleText
        edit={edit}
        form={form}
        itemClassName={"py-2"}
        displayClassName={"text-sm font-medium text-muted-foreground"}
        className={"bg-primary/10 text-sm font-medium text-muted-foreground"}
        name={issuer ? "issuer.address" : "recipient.address"}
        placeholder={t("model.document.issuer.address")}
        textarea
        label={t("model.document.issuer.address")}
      />
      <FormSimpleText
        edit={edit}
        itemClassName={"flex-1 py-2"}
        inputClassName={"min-w-32"}
        form={form}
        name={issuer ? "issuer.taxId" : "recipient.taxId"}
        placeholder={t("model.document.issuer.taxId")}
        label={t("model.document.issuer.taxId")}
      />
      <FormSimpleText
        edit={edit}
        itemClassName={"flex-1 py-2"}
        inputClassName={"min-w-32"}
        form={form}
        name={issuer ? "issuer.iban" : "recipient.iban"}
        placeholder={t("model.document.issuer.iban")}
        label={t("model.document.issuer.iban")}
      />

      {/* MONEY AMOUNTS*/}
      <div className="divide-y">
        <div className="mt-4">
          <HorizontalFlex className="justify-between">
            <FormLabel className={"text-muted-foreground"}>
              {t("model.document.totalNetAmount")}
              {": "}
            </FormLabel>
            <span
              className={cn(
                "flex font-semibold text-muted-foreground",
                totalNetAmount < 0 ? "text-red-300" : "",
                edit && "mr-3",
              )}
            >
              {formatNumberFancy(totalNetAmount)}
              {edit && <span className={cn("ml-3")}>€</span>}
              {!edit && " €"}
            </span>
          </HorizontalFlex>
          <HorizontalFlex className="justify-between">
            <FormLabel className={"text-muted-foreground"}>
              {t("model.document.totalTaxAmount")}
              {": "}
            </FormLabel>

            <FormSimpleMoney
              form={form}
              name={"totalTaxAmount"}
              edit={edit}
              className={"font-semibold"}
            />
          </HorizontalFlex>
        </div>
        <HorizontalFlex className="justify-between">
          <FormLabel className={"text-muted-foreground"}>
            {t("model.document.totalAmount")}
            {": "}
          </FormLabel>

          <FormSimpleMoney
            form={form}
            name={"totalAmount"}
            edit={edit}
            className={"font-semibold"}
          />
        </HorizontalFlex>
      </div>

      {/*CATEGORY*/}
      <EditCategorySelection edit={edit} form={form} document={document} />

      {/*DATE STUFF*/}
      <div className="mb-4 grid grid-cols-1 gap-4 pt-4 2xl:grid-cols-2">
        <div className="flex flex-auto flex-col align-middle">
          <FormLabel className={"text-muted-foreground"}>
            {" "}
            {t("model.document.invoiceDate")}
          </FormLabel>
          <div className="flex items-center">
            <FormSimpleDate
              itemClassName={"flex-1"}
              className={"w-full"}
              form={form}
              name={"invoiceDate"}
              edit={edit}
            />
          </div>
        </div>
        <div className="flex flex-auto flex-col align-middle">
          <FormLabel className={"text-muted-foreground"}>
            {t("model.document.dueDate")}
          </FormLabel>
          <div className="flex items-center">
            <FormSimpleDate
              placeholder={
                invoiceDate ? formatSimpleDate(invoiceDate) : undefined
              }
              optional={true}
              itemClassName={"flex-1"}
              className={"w-full"}
              form={form}
              name={"dueDate"}
              edit={edit}
            />
          </div>
        </div>
      </div>
      <FormSimpleDateRange
        document={document}
        form={form}
        placeholderSingle={
          invoiceDate ? formatSimpleDate(invoiceDate) : undefined
        }
      />
    </>
  );
};
