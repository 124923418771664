import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";

import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import { useResetMutation } from "@/api/endpoints/authApi";
import FormErrorDisplay from "@/components/error/FormErrorDisplay";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/feature/auth/AuthProvider";
import { UserCompanyStatus } from "@/models/userCompany";

const resetFormSchema = z.object({
  newPassword: z.string().min(8),
  token: z.string().min(1),
});

type ResetFormValues = z.infer<typeof resetFormSchema>;

type ResetFormProps = {
  resetToken: string;
};

export function ResetForm(props: ResetFormProps) {
  const { resetToken } = props;

  const { t } = useTranslation();
  const [reset, result] = useResetMutation();

  const defaultValues: Partial<ResetFormValues> = {
    newPassword: "",
    token: resetToken,
  };
  const navigation = useNavigate();
  const auth = useAuth();

  const form = useForm<ResetFormValues>({
    resolver: zodResolver(resetFormSchema),
    defaultValues,
    mode: "onBlur",
  });

  const onSubmit = async (data: ResetFormValues) => {
    await reset(data).then((data) => {
      if ("data" in data) {
        let identities = data.data.identities.filter(
          (i) => i.status == UserCompanyStatus.ACTIVE,
        );
        auth.login(data.data.token, identities[0].company);
        navigation("/");
      }
    });
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex w-96 flex-col space-y-2"
      >
        {/* PASSWORD */}
        <FormField
          control={form.control}
          name="newPassword"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("model.user.passwordReset")}</FormLabel>
              <FormControl>
                <Input type="password" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {"error" in result && <FormErrorDisplay error={result.error} />}{" "}
        <div className="flex justify-center pt-8">
          <Button
            type="submit"
            className="mx-auto w-full max-w-xs"
            disabled={result.isLoading || result.isSuccess || result.isError}
          >
            {t("component.auth.reset.resetAction")}
          </Button>
        </div>
      </form>
    </Form>
  );
}
