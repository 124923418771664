import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { User } from "@/models/user";
type ProfileAvatarProps = {
  user: User;
};

const ProfileAvatar = ({ user }: ProfileAvatarProps) => {
  const img = "work-in-progress";
  return (
    <Avatar>
      <AvatarImage src={img} alt="@shadcn" />
      <AvatarFallback>
        {user.firstname[0] ?? ""} {user.lastname[0] ?? ""}
      </AvatarFallback>
    </Avatar>
  );
};

export default ProfileAvatar;
