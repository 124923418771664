import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Fragment, useState } from "react";
import { ArrowDown, ArrowUp } from "lucide-react";
import { HorizontalFlex } from "@/components/layout/Flex";

export interface DataTableSortSelectorProps<T> {
  options: {
    label: string;
    value: keyof T & string;
  }[];
  default?: [keyof T & string, "asc" | "desc"];
  onChange?: (sort: [keyof T & string, "asc" | "desc"]) => void;
}

export const DataTableSortSelector = <T,>(
  props: DataTableSortSelectorProps<T>,
) => {
  const [sort, setSort] = useState<[keyof T & string, "asc" | "desc"]>(
    props.default || [props.options[0].value, "asc"],
  );

  return (
    <Select
      value={sort[0] + ":" + sort[1]}
      onValueChange={(val) => {
        const [value, direction] = val.split(":", 2) as [
          keyof T & string,
          "asc" | "desc",
        ];
        setSort([value, direction]);
        props.onChange?.([value, direction]);
      }}
    >
      <SelectTrigger>
        <SelectValue />
      </SelectTrigger>
      <SelectContent>
        {props.options.map((option) => (
          <Fragment key={option.value}>
            <SelectItem value={option.value + ":desc"}>
              <HorizontalFlex className={"items-center"}>
                <ArrowDown className="ml-2 h-4 w-4" />
                {option.label}
              </HorizontalFlex>
            </SelectItem>
            <SelectItem value={option.value + ":asc"}>
              <HorizontalFlex className={"items-center"}>
                <ArrowUp className="ml-2 h-4 w-4" />
                {option.label}
              </HorizontalFlex>
            </SelectItem>
          </Fragment>
        ))}
      </SelectContent>
    </Select>
  );
};
