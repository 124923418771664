import { reduxApi } from "@/api/reduxApi";
import { SimpleDate } from "@/models/document";

export interface FinanceStatisticDataPoint {
  from: SimpleDate;
  to: SimpleDate;
  balance: number;
  expenses: number;
  income: number;
}

const analyticsApi = reduxApi.injectEndpoints({
  endpoints: (builder) => ({
    // ** DASHBOARD / ANALYTICS **
    // KPI (now)
    finance: builder.query<
      { data: FinanceStatisticDataPoint[] },
      { ranges?: { from: SimpleDate; to: SimpleDate }[]; fit?: boolean }
    >({
      query: (body) => ({
        url: "analytics/finance",
        method: "POST",
        body: body,
      }),
    }),
  }),
});

export const { useFinanceQuery } = analyticsApi;
