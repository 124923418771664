import * as React from "react";
import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Contact, ISO_COUNTRIES } from "@/models/contact";
import { UseFormReturn } from "react-hook-form";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { t } from "i18next";

const CountrySelect = ({
  form,
  index,
}: {
  form: UseFormReturn<Contact>;
  index: number;
}) => {
  const [open, setOpen] = React.useState(false);
  const [query, setQuery] = React.useState("");

  const filteredCountries =
    query === ""
      ? ISO_COUNTRIES
      : ISO_COUNTRIES.filter(
          (country) =>
            country.name.toLowerCase().includes(query.toLowerCase()) ||
            country.code.toLowerCase().includes(query.toLowerCase()),
        );

  const { control, setValue, watch } = form;

  React.useEffect(() => {
    const currentCountry = watch(`addresses.${index}.country`);
    if (!currentCountry) {
      setValue(`addresses.${index}.country`, "DE");
    }
  }, [index, setValue, watch]);

  return (
    <FormField
      control={control}
      name={`addresses.${index}.country`}
      render={({ field }) => (
        <FormItem className="flex flex-col gap-2.5">
          <FormLabel>{t("component.contacts.addresses.country")}</FormLabel>
          <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="outline"
                  role="combobox"
                  className={cn(
                    "w-[200px] justify-between",
                    !field.value && "text-muted-foreground",
                  )}
                >
                  {field.value
                    ? ISO_COUNTRIES.find(
                        (country) => country.code === field.value,
                      )?.name
                    : "Wähle ein Land"}
                  <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[200px] p-0">
              <Command>
                <CommandInput
                  placeholder="Suche nach Land..."
                  className="h-9"
                  value={query}
                  onValueChange={(value) => setQuery(value)}
                />
                <CommandList>
                  <CommandEmpty>Kein Land gefunden.</CommandEmpty>
                  <CommandGroup>
                    {filteredCountries.map((country) => (
                      <CommandItem
                        value={country.name}
                        key={country.code}
                        onSelect={() => {
                          setValue(`addresses.${index}.country`, country.code);
                          setOpen(false);
                        }}
                      >
                        {country.name}
                        <CheckIcon
                          className={cn(
                            "ml-auto h-4 w-4",
                            country.code === field.value
                              ? "opacity-100"
                              : "opacity-0",
                          )}
                        />
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </CommandList>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default CountrySelect;
