import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@/components/ui/card";
import React from "react";

import { cn } from "@/lib/utils";
import { VerticalFlex } from "@/components/layout/Flex";
import DocumentCardHeader from "@/feature/documents/components/Card/CardHeader/DocumentCardHeader";
import HorizontalGradientLine from "@/components/layout/HorizontalGradientLine";
import EditDocumentCardBody from "@/feature/documents/components/Card/CardBody/EditForm/EditDocumentCardBody";
import { DocumentCardBody } from "@/feature/documents/components/Card/CardBody/DocumentCardBody";
import { DocumentPreview } from "@/feature/documents/components/Card/CardFooter/DocumentPreview";
import { BiDocument } from "@/models/document";
import { useDocumentContext } from "@/feature/documents/DocumentContextProvider";
import { ScrollArea } from "@/components/ui/scroll-area";

const DocumentCard = ({ biDocument }: { biDocument: BiDocument }) => {
  const { mode, setMode } = useDocumentContext();

  if (!biDocument) return null;

  return (
    <VerticalFlex className={cn("sticky top-[74px]")}>
      <Card className="relative flex h-full w-full flex-col border-none border-red-600 shadow active:border-2">
        <CardHeader className={"sticky top-0 mb-2 w-full pb-0"}>
          <VerticalFlex>
            <DocumentCardHeader document={biDocument} />
            <HorizontalGradientLine />
          </VerticalFlex>
        </CardHeader>
        <ScrollArea
          style={{
            maxHeight: "calc(100vh - 160px)",
          }}
        >
          <>
            <CardContent
              contextMenu={undefined}
              onDoubleClick={(e) => {
                setMode("edit");
              }}
              className={"flex flex-1 flex-col"}
            >
              <>
                {mode === "edit" ? (
                  <EditDocumentCardBody
                    biDocument={biDocument}
                    onCancel={() => {
                      setMode("small");
                    }}
                  />
                ) : (
                  <>
                    <DocumentCardBody biDocument={biDocument} />
                    <HorizontalGradientLine />
                  </>
                )}
              </>
            </CardContent>
            {(mode === "small" || mode === "preview") && (
              <CardFooter>
                <DocumentPreview document={biDocument} />
              </CardFooter>
            )}
          </>
        </ScrollArea>
      </Card>
    </VerticalFlex>
  );
};

export default DocumentCard;
