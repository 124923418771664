import { reduxApi } from "../reduxApi";
import { User, UserUpdateRequest } from "@/models/user";
import { UserCompany } from "@/models/userCompany";

const userApi = reduxApi.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<{ user: User; identities: UserCompany[] }, void>({
      query: () => "auth/me",
      providesTags: ["User"],
    }),
    updateUser: builder.mutation<User, UserUpdateRequest>({
      query: (body) => ({
        url: "auth/me",
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["User"],
    }),
    findAllUser: builder.query<UserCompany[], void>({
      query: () => "user/",
      providesTags: ["User"],
    }),
  }),
});

export const { useGetUserQuery, useUpdateUserMutation, useFindAllUserQuery } =
  userApi;
